import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Constants from "../../services/constant";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { useHistory } from "react-router-dom";
import { getData, postData, postData1, putData, putData1 } from "../../services/http.service";
import { InputTextarea } from "primereact/inputtextarea";
import Cross from "../../assets/demo/flags/cross.png";
import moment from "moment/moment";
import { Image } from "primereact/image";
import profile from "../../assets/demo/flags/profile.png";
import service_dummy from "../../assets/demo/flags/service-dummy.svg";
import { Chips } from "primereact/chips";
import { MultiSelect } from 'primereact/multiselect';

const ServicesEdit = () => {
    const { id } = useParams();
    const toast = useRef(null);
    const history = useHistory();
 
   const [serviceImg,setServiceImg] = useState(null)
    const [serviceInfo, setServiceInfo] = useState({
        serviceName:"",
        seasonId:"",
        serviceImage:"",
        imageColorCode:"",
        minPercentage:""
    });

    const [allSeason,setAllSeason] = useState([])

    const [err, setErr] = useState({});
    const [loading, setLoading] = useState(true);

const handleChangeService = (e) => {
    const {name,value} = e.target

        setServiceInfo((prev)=>{
            return {
                ...prev,
                [name] : value
            }
        })

    setErr({ ...err, [name]: "" })
}

    console.log("err", err);
    console.log("serviceInfo", serviceInfo);

    const validateForm = () => {
        let errors = {};
        if (!serviceInfo.serviceName) {
            errors.serviceName = "Service Name is required";
        }
        if (!serviceInfo.seasonId) {
            errors.seasonId = "Season Name is required";
        }
        if (!serviceInfo.imageColorCode) {
            errors.imageColorCode = "image Background color is required";
        }
        if (!serviceInfo.minPercentage) {
            errors.minPercentage = "Minimum Percentage is required";
        }
        // if (!serviceImg) {
        //     errors.serviceImage = "service Image is required";
        // }

        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = validateForm();
        if (form) {
            if(serviceImg){
                const formData = new FormData();
                formData.append("serviceImage", serviceImg);
                postData1(Constants.END_POINT.UPLOAD_IMAGE, formData)
                    .then((res) => {
                        if (res.success) {
                          let seasonsId = serviceInfo.seasonId.map((item)=>{
                                return item._id
                            })
                            let payload = {
                                serviceImage:res.data.image,
                                serviceName:serviceInfo.serviceName,
                                seasonId:seasonsId,
                                imageColorCode:serviceInfo.imageColorCode,
                                minPercentage:serviceInfo.minPercentage
                            }
                            putData(Constants.END_POINT.UPDATE_SERVICE + id, payload)
                            .then((res) => {
                                if (res.success) {
                                    toast.current.show({ severity: "info", summary: "", detail: "Service has been Updated Succesfully", life: 3000 });
                                                setTimeout(()=>history.push("/services"), 1000)
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            else{
                let seasonsId = serviceInfo.seasonId.map((item)=>{
                    return item._id
                })
                let payload = {
                    serviceImage:serviceInfo.serviceImage,
                    serviceName:serviceInfo.serviceName,
                    seasonId:seasonsId,
                    imageColorCode:serviceInfo.imageColorCode,
                    minPercentage:serviceInfo.minPercentage
                }
                putData(Constants.END_POINT.UPDATE_SERVICE + id, payload)
                .then((res) => {
                    if (res.success) {
                        toast.current.show({ severity: "info", summary: "", detail: "Service has been Updated Succesfully", life: 3000 });
                                    setTimeout(()=>history.push("/services"), 1000)
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }
        } else {
            window.scrollTo({
                top: 250,
                left: 0,
                behavior: "smooth",
            });
        }
    };

    const allPercentage = [
        {name:'10%',value:10},
        {name:'20%',value:20},
        {name:'30%',value:30},
        {name:'40%',value:40},
        {name:'50%',value:50},
        {name:'60%',value:60},
    ]

    const getAllSeasons = () => {
        getData(Constants.END_POINT.ALL_SEASONS)
            .then((res) => {
                if (res.success) {
                 let array = res.data.map((item)=>{
                        return {
                            _id:item._id,
                            seasonName:item.seasonName
                        }
                    })
                    setAllSeason(array);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };


useEffect(() => {
    getAllSeasons();
}, [])

useEffect(() => {
    getSingleService();
}, []);

const getSingleService = () => {
    getData(Constants.END_POINT.EDIT_SERVICE + id)
        .then((res) => {
            if (res.success) {
                // console.log("resss",res.data)
                let obj = {
                    serviceName: res.data.serviceName,
                    seasonId: res.data.seasonId,
                    serviceImage: res.data.serviceImage,
                    imageColorCode: res.data.imageColorCode,
                    minPercentage: res.data.minPercentage
                }
                setServiceInfo(obj)
                setLoading(false)
            }
        })
        .catch((err) => {
            console.log(err);
        });
};


console.log("serviceInfooo",serviceInfo)

    return (
        <div className="grid">
            <div className="col-12 md:col-12 mx-auto">
                <Toast ref={toast} />
                <div className="card ">
                {loading ? 
                    <div className="overlay-loading">
                    <div class="spinner-border text-dark" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                : null}
                    <div className=" flex justify-content-center">
                        <h3>Edit Service</h3>
                        <div className="ml-4 align-right"></div>
                    </div>
                    {/* <img src={product} alt="" width="10%" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} /> */}
                    <div className="p-fluid grid">
                        <div className="field col-12 md:col-12">
                            <div className="weapon-image-wrapper mt-4">
                                <div className="weapon-image-div">
                                    <img src={serviceImg ? URL.createObjectURL(serviceImg) : serviceInfo.serviceImage !== "" ? Constants.BASE_URL + serviceInfo.serviceImage : service_dummy} alt="" className="weaponImageTag" />
                                    {serviceImg!==null ?
                                    <div className="weapon-edit-div">
                                        <i onClick={()=>setServiceImg(null)}  className="fa-sharp fa-solid fa-trash delete"></i>
                                    </div>
                                    : null }
                                </div>

                                <input
                                    type="file"
                                    id="serviceImage"
                                    name="serviceImage"
                                    onChange={(e) => {
                                        setServiceImg(e.target.files[0]);
                                        setErr({ ...err, serviceImage: "" });
                                    }}
                                    onClick={(event)=> { 
                                        event.currentTarget.value = null
                                   }}
                                    hidden
                                />
                                <label htmlFor="serviceImage" className="weaponImageLabel">
                                    Update Service Image
                                </label>
                                {/* <div className="text-danger">{err.serviceImage}</div> */}
                            </div>
                            {/* <div className="text-danger">{err.serviceName}</div> */}
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <InputText id="serviceName" name="serviceName" onChange={handleChangeService} value={serviceInfo.serviceName} />
                                <label htmlFor="serviceName">Service Name</label>
                            </span>
                            <div className="text-danger">{err.serviceName}</div>
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                            {/* <Dropdown value={serviceInfo.seasonId} name="seasonId" onChange={handleChangeService} options={allSeason} optionLabel="seasonName" 
                placeholder="" className="w-full" /> */}
                <MultiSelect value={serviceInfo.seasonId} name="seasonId" onChange={handleChangeService} options={allSeason} optionLabel="seasonName" display="chip" 
                placeholder="" maxSelectedLabels={6} className="w-full" />
                                <label htmlFor="serviceName">Season</label>
                            </span>
                            <div className="text-danger">{err.serviceName}</div>
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <InputText id="imageColorCode" name="imageColorCode" onChange={handleChangeService} value={serviceInfo.imageColorCode} />
                                <label htmlFor="imageColorCode">Image Color Code</label>
                            </span>
                            <div className="text-danger">{err.imageColorCode}</div>
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                            <Dropdown name="minPercentage" value={serviceInfo.minPercentage} onChange={handleChangeService} options={allPercentage} optionLabel="name" 
                placeholder="" className="w-full" />
                                {/* <InputText id="minPercentage" name="minPercentage" onChange={(e)=>{setServiceInfo(e.target.value);setErr({ ...err, minPercentage: "" });}} value={serviceInfo.minPercentage} /> */}
                                <label htmlFor="minPercentage">Minimum Percentage</label>
                            </span>
                            <div className="text-danger">{err.minPercentage}</div>
                        </div>
                        
                       
                    </div>
         

                    <div className="flex justify-content-center mt-3 ">
                        {
                            <Button type="button" className="p-button-md p-button-success" onClick={handleSubmit}>
                                Update Service
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServicesEdit;
