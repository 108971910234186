import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useHistory } from "react-router-dom";
import { getData, postData, postData1, putData } from "../../services/http.service";
import Constants from "../../services/constant";
import { Chips } from "primereact/chips";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const SeasonEdit = () => {
    const { id } = useParams();
    const toast = useRef(null);
    const history = useHistory();

    const [err, setErr] = useState({});
    const [season, setSeason] = useState(null);
    const [loading, setLoading] = useState(true);

    const validateForm = () => {
        let errors = {};
        if (!season) {
            errors.seasonName = "Season Name is required";
        }

        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = validateForm();
        if (form) {
          let  payload={
                seasonName:season
            }
            putData(Constants.END_POINT.UPDATE_SEASON + id, payload)
            .then((res) => {
                if (res.success) {
                    console.log("res", res);
                    toast.current.show({ severity: "info", summary: "", detail: "Season has been Updated Succesfully", life: 3000 });
                    setTimeout(()=>history.push("/seasons"), 1000)
                    ;
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
    }


    useEffect(() => {
        getSingleSeason();
    }, []);

    const getSingleSeason = () => {
        getData(Constants.END_POINT.EDIT_SEASON + id)
            .then((res) => {
                if (res.success) {
                   setSeason(res.data.seasonName)
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    

    return (
        <div className="grid">
            <div className="col-12 md:col-12 mx-auto">
                <Toast ref={toast} />
                <div className="card ">
                {loading ? 
                    <div className="overlay-loading">
                    <div class="spinner-border text-dark" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                : null}
                    <div className=" flex justify-content-left">
                        <h3>Edit Season</h3>
                        <div className="ml-4 align-right"></div>
                    </div>
                    <div className="p-fluid grid question-div-wrapper mt-4">
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label">
                                <InputText id="seasonName" name="seasonName" onChange={(e)=>{setSeason(e.target.value);setErr({ ...err, seasonName: "" });}} value={season} />
                                <label htmlFor="seasonName">Season Name</label>
                            </span>
                            <div className="text-danger">{err.seasonName}</div>
                        </div>

                    </div>
                    <div className="flex justify-content-left mt-3 ">
                        {
                            <Button type="button" className="p-button-md p-button-success" onClick={handleSubmit}>
                                Update Season
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SeasonEdit;
