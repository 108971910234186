import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { deleteData, getData } from "../../services/http.service";
import Constants from "../../services/constant";
// import profile from "../assets/demo/flags/profile.png";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { Rating } from "primereact/rating";
import { formatDate } from "../../utils/dateConversions";
import dummy from "../../assets/images/dummy1.png";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Chips } from "primereact/chips";
import { InputSwitch } from "primereact/inputswitch";

const Services = () => {
    const toast = useRef(null);
    useEffect(() => {
        getAllServices();
    }, []);
    const getAllServices = () => {
        getData(Constants.END_POINT.ALL_SERVICES)
            .then((res) => {
                if (res.success) {
                    setServices(res.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const deleteService = (id) => {
        deleteData(Constants.END_POINT.DELETE_SERVICE + id).then((res) => {
            if (res.success) {
                toast.current.show({ severity: "info", summary: "", detail: "Service Deleted Succesfully", life: 3000 });
                getAllServices();
            }
        });
    };

    const [services, setServices] = useState();
    const [loading, setLoading] = useState(true);

    const servicesImageTemplate = (rowData) => {
        return <img src={rowData?.serviceImage ? Constants.BASE_URL + rowData?.serviceImage : dummy} style={{ width: "70px", height: "70px" }} />;
    };

    const acceptFunc = (id) => {
        deleteService(id);
        // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    };

    const reject = () => {
        // toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };

    const deleteConfirm = (id) => {
        confirmDialog({
            message: "Do you want to delete this record?",
            header: "Delete Confirmation",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            rejectClassName: "cancel-button",
            accept: () => acceptFunc(id),
            reject,
        });
    };

    const changeStatus = (id) => {
        getData(Constants.END_POINT.ENABLE_DISABLE_SERVICE + id)
            .then((res) => {
                if (res.success) {
                    getAllServices();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const ActionBodyTemplate = (rowData) => {
        return (
            <div className="flex">
                <Link to={`/service-edit/${rowData?._id}`}>
                    <Button type="button" className="p-button-info mx-2">
                        Edit
                    </Button>
                </Link>

                <Button type="button" className="p-button-danger " onClick={() => deleteConfirm(rowData?._id)}>
                    Delete
                </Button>

                <Link to={`/service-questions/${rowData?._id}`}>
                    <Button type="button" className="p-button-info mx-2">
                        Questions
                    </Button>
                </Link>
                {/* 
                <Link to={`/service-locations/${rowData?._id}`}>
                    <Button type="button" className="p-button-info mx-2">
                        Locations
                    </Button>
                </Link> */}
            </div>
        );
    };

    const customChip = (item) => {
        return (
            <div>
                <span>{item.seasonName}</span>
            </div>
        );
    };

    const SeasonBodyTemplate = (rowData) => {
        return (
            <div className="flex">
                <Chips className="service-chilp" value={rowData.seasonId} disabled itemTemplate={customChip} />
            </div>
        );
    };

    const statusTemplate = (rowData) => {
        return (
            <div className="flex" style={{ alignItems: "center" }}>
                <InputSwitch checked={rowData?.isActive} onChange={(e) => changeStatus(rowData._id)} />
                {rowData?.isActive ? <div className="status-button status-active">Active</div> : <div className="status-button status-inactive">InActive</div>}
            </div>
        );
    };
    return (
        <div className="col-12">
            <div className="card">
                <div className="md:flex justify-content-between mb-4">
                    <Toast ref={toast} />
                    <h5>Services</h5>
                    <div className=" mr-3">
                        <Link to="/create-service">
                            <Button className="p-button-info" icon="pi pi-plus" label="Create Service" />
                        </Link>
                        {/* <Button label="Export" icon="pi pi-upload" className="p-button-help ml-3" onClick={() => ExportCSV(exportData)} /> */}
                    </div>
                </div>
                <DataTable value={services} paginator rows={10} loading={loading} responsiveLayout="scroll">
                    <Column field="serviceName" header="Service Name" sortable />
                    <Column field="serviceImage" header="Service Image" sortable body={servicesImageTemplate} />
                    <Column field="seasonId" header="Seasons" sortable body={SeasonBodyTemplate} />
                    <Column field="status" header="Status" sortable body={statusTemplate} />
                    <Column field="block" header="Action" body={ActionBodyTemplate} style={{ width: "10px" }} />
                </DataTable>
            </div>
        </div>
    );
};

export default Services;
