import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { confirmPopup } from "primereact/confirmpopup";
import { useHistory } from "react-router-dom";
import { logout } from "./services/auth";
import logo from "../src/assets/demo/flags/logo.png";
import { getData } from "./services/http.service";
import { Badge } from "primereact/badge";
import Constants from "./services/constant";
import { Menu } from "primereact/menu";

export const AppTopbar = (props) => {
    const history = useHistory();
    const menuLeft = useRef(null);
    const [showNotifications, setNotifications] = useState([]);
    const [showNotifiCount, setNotifiCount] = useState([]);
    const confirm = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: "Are you sure you want to Logout?",
            icon: "pi pi-exclamation-triangle",
            accept,
            reject,
        });
    };
    const accept = () => {
        logout(() => {
            setTimeout(() => {
                history.push("/");
            }, 400);
        });
    };
    const reject = () => {};

    const navigateToPage = (path) => {
        history.push(path);
    };
    const getAllProfile = () => {
        getData(Constants.END_POINT.GET_ADMIN_NOTIFICATIONS)
            .then((res) => {
                if (res.success) {
                    setNotifications(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const getNotiCount = () => {
        getData(Constants.END_POINT.GET_ADMIN_NOTIFI_COUNT)
            .then((res) => {
                if (res.success) {
                    setNotifiCount(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const notificationitems = () => {
        return (
            <div>
                <div className="border-round-xl">
                    <div className="flex justify-content-between align-items-center p-2 ">
                        <h6 className="m-2 mb-0 text-blue-300 text-bold">Notifications</h6>
                    </div>
                    <hr className="m-0" />

                    {showNotifications?.map((item, index) => {
                        return (
                            <>
                                <div className="bg-gray-200 pt-2 cursor-pointer">
                                    <h6 className="my-0 mx-2 text-xs ">{item?.title}</h6>
                                    <h6 className="my-0 mx-2 text-sm">{item?.desc}</h6>
                                    <hr className="m-1" />
                                </div>
                            </>
                        );
                    })}
                </div>
            </div>
        );
    };

    let notification = [
        {
            label: "Search",
            icon: "pi pi-search",
            shortcut: "⌘+S",
            template: notificationitems,
        },
    ];

    useEffect(() => {
        getAllProfile();
        getNotiCount();
    }, []);
    return (
        <div className="layout-topbar custom-top-bar">
            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick} style={{ color: "#ffffff" }}>
                <i className="pi pi-bars " />
            </button>
            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick} style={{ color: "#ffffff" }}>
                <i className="pi pi-ellipsis-v" />
            </button>
            <Link to="/" className="layout-topbar-logo ml-3">
                {/* <img src={props.layoutColorMode === "light" ? "assets/layout/images/squareLogo.png" : "assets/layout/images/squareLogo.png"} alt="logo" /> */}
                <img width="55px" src={logo} alt="logo" />
                {/* <span>
                    {" "}
                    INSTA<span style={{ color: "#ffdb4d" }}>JAMAICA</span>
                </span> */}
            </Link>

            <ul className={classNames("layout-topbar-menu lg:flex origin-top", { "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive })}>
                <li className="relative ">
                    <button className="p-link layout-topbar-button" style={{ color: "#ffffff" }} onClick={(event) => menuLeft.current.toggle(event)} aria-controls="popup_menu_left" aria-haspopup>
                        <i className="pi pi-bell relative" />

                        <span>Events</span>
                    </button>
                    <Menu model={notification} popup ref={menuLeft} id="popup_menu_left" className="w-30rem" />

                    {showNotifiCount?.notificationCount ? <Badge className="absolute top-0 right-0" value={showNotifiCount?.notificationCount} severity=""></Badge> : ""}
                </li>
                <li>
                    <button className="p-link layout-topbar-button" style={{ color: "#ffffff" }} onClick={() => navigateToPage("/profile")}>
                        <i className="pi pi-cog" />
                        <span>Settings</span>
                    </button>
                </li>
                <li>
                    <button className="p-link layout-topbar-button" onClick={confirm} style={{ color: "#ffffff" }}>
                        <i className="pi pi-power-off" />
                        <span>Logout</span>
                    </button>
                </li>
            </ul>
        </div>
    );
};
