import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getData } from "../services/http.service";
import Constants from "../services/constant";
import profile from "../assets/demo/flags/profile.png";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { InputText } from "primereact/inputtext";

const Users = () => {
    const [globalFilter, setGlobalFilter] = useState("");
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getAllUsers();
    }, []);
    const getAllUsers = () => {
        getData(Constants.END_POINT.USERS)
            .then((res) => {
                if (res.success) {
                    setUsers(res.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    // console.log("user",users)

    const imageBodyTemplate = (rowData) => {
        return <img src={rowData.image ? Constants.BASE_URL + rowData.image : profile} alt={rowData.image} width={50} />;
    };
    const statusBodyTemplate2 = (rowData) => {
        return <span className={`product-badge status-${rowData.isVerified ? "instock" : "outofstock"}`}>{rowData.isVerified ? "Verified" : "UnVerified"}</span>;
    };

    const Delete = (id) => {};

    const reject = () => {
        // toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };

    const deleteConfirm = (id) => {
        confirmDialog({
            message: "Do you want to delete this record?",
            header: "Delete Confirmation",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            rejectClassName: "cancel-button",
            accept: () => Delete(id),
            reject,
        });
    };
    const ActionBodyTemplate = (rowData) => {
        return (
            <div className=" flex ">
                <Link to={`/user-view/${rowData?._id}`}>
                    <Button type="button" className="p-buuton-raised p-button-rounded p-button-detail mx-2">
                        View
                    </Button>
                </Link>
                {/* <Button type="button" className={`p-button-raised p-button-rounded  p-button-outlined ${rowData?.isDeleted ? "p-button-success" : "p-button-danger"}`} onClick={() => blockUser(rowData?._id)}>
                    {rowData?.isDeleted ? "Unblock" : "Block"}
                </Button> */}
                <Button type="button" className={`p-button-raised p-button-rounded  p-button-outlined p-button-danger`} onClick={() => deleteConfirm(rowData?._id)}>
                    Delete
                </Button>
            </div>
        );
    };

    const nameTemplate = (rowData) => {
        return (
            <div>
                {rowData.firstName} {rowData.lastName}
            </div>
        );
    };

    const mobileTemplate = (rowData) => {
        return (
            <div>
                {rowData.countryCode} {rowData.mobile}
            </div>
        );
    };
    const getHeader = () => {
        return (
            <div className="flex justify-content-end ">
                <div className="p-input-icon-left">
                    <i className="pi pi-search"></i>
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} className="w-full" placeholder="Search" />
                </div>
            </div>
        );
    };

    return (
        <div className="col-12">
            <div className="card">
                <h5>Users</h5>
                <DataTable value={users} paginator header={getHeader} globalFilter={globalFilter} rows={10} loading={loading} responsiveLayout="scroll">
                    <Column field="firstName" header="Name" sortable body={nameTemplate} />
                    <Column field="email" header="Email" sortable />
                    <Column field="mobile" header="Mobile" sortable body={mobileTemplate} />
                    <Column header="Image" body={imageBodyTemplate} />
                    <Column field="block" header="Action" body={ActionBodyTemplate} style={{ width: "10px" }} />
                </DataTable>
            </div>
        </div>
    );
};

export default Users;
