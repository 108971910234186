import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useHistory } from "react-router-dom";
import { postData, postData1 } from "../../services/http.service";
import Constants from "../../services/constant";
import { Chips } from "primereact/chips";

const CreateReason = () => {
    const toast = useRef(null);
    const history = useHistory();

    const [err, setErr] = useState({});
    const [reason, setReason] = useState(null);

    console.log("reason", reason);

    const validateForm = () => {
        let errors = {};
        if (!reason) {
            errors.title = "title is required";
        }

        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = validateForm();
        if (form) {
            let payload = {
                title: reason,
            };
            postData(Constants.END_POINT.CREATE_REASON, payload)
                .then((res) => {
                    if (res.success) {
                        console.log("res", res);
                        toast.current.show({ severity: "info", summary: "", detail: "Reason has been Created Succesfully", life: 3000 });
                        setTimeout(() => history.push("/reasons"), 1000);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    return (
        <div className="grid">
            <div className="col-12 md:col-12 mx-auto">
                <Toast ref={toast} />
                <div className="card ">
                    <div className=" flex justify-content-left">
                        <h3>Add Reason</h3>
                        <div className="ml-4 align-right"></div>
                    </div>
                    <div className="p-fluid grid question-div-wrapper mt-4">
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label">
                                <InputText
                                    id="title"
                                    name="title"
                                    onChange={(e) => {
                                        setReason(e.target.value);
                                        setErr({ ...err, title: "" });
                                    }}
                                    value={reason}
                                />
                                <label htmlFor="title">Title</label>
                            </span>
                            <div className="text-danger">{err.title}</div>
                        </div>
                    </div>
                    <div className="flex justify-content-left mt-3 ">
                        {
                            <Button type="button" className="p-button-md p-button-success" onClick={handleSubmit}>
                                Add Reason
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateReason;
