import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useHistory } from "react-router-dom";
import { getData, postData, postData1, putData } from "../../services/http.service";
import Constants from "../../services/constant";
import { Chips } from "primereact/chips";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const ReasonEdit = () => {
    const { id } = useParams();
    const toast = useRef(null);
    const history = useHistory();

    const [err, setErr] = useState({});
    const [reason, setReason] = useState(null);
    const [loading,setLoading] = useState(false)

console.log("reason",reason)


    const validateForm = () => {
        let errors = {};
        if (!reason) {
            errors.title = "title is required";
        }

        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = validateForm();
        if (form) {
          let  payload={
                title:reason
            }
            putData(Constants.END_POINT.UPDATE_REASON + id, payload)
            .then((res) => {
                if (res.success) {
                    console.log("res", res);
                    toast.current.show({ severity: "info", summary: "", detail: "Reason has been Updated Succesfully", life: 3000 });
                    setTimeout(()=>history.push("/reasons"), 1000)
                    ;
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
    }

    useEffect(() => {
        getSingleReason();
    }, []);

    const getSingleReason = () => {
        getData(Constants.END_POINT.EDIT_REASON + id)
            .then((res) => {
                if (res.success) {
                    setReason(res.data.title)
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div className="grid">
            <div className="col-12 md:col-12 mx-auto">
                <Toast ref={toast} />
                <div className="card ">
                {loading ? 
                    <div className="overlay-loading">
                    <div class="spinner-border text-dark" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                : null}
                    <div className=" flex justify-content-left">
                        <h3>Edit Reason</h3>
                        <div className="ml-4 align-right"></div>
                    </div>
                    <div className="p-fluid grid question-div-wrapper mt-4">
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label">
                            <InputText id="title" name="title" onChange={(e)=>{setReason(e.target.value);setErr({ ...err, title: "" });}} value={reason} />
                                <label htmlFor="title">Title</label>
                            </span>
                            <div className="text-danger">{err.title}</div>
                        </div>

                    </div>
                    <div className="flex justify-content-left mt-3 ">
                        {
                            <Button type="button" className="p-button-md p-button-success" onClick={handleSubmit}>
                                Update Reason
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};


export default ReasonEdit;
