import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getData, postData, postDataParam } from "../../services/http.service";
import Constants from "../../services/constant";
import profile from "../../assets/demo/flags/profile.png";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";

const Provider = () => {
    const [globalFilter, setGlobalFilter] = useState("");
    const [providers, setProviders] = useState([]);
    const [loading, setLoading] = useState(true);
    const toast = useRef(null);
    useEffect(() => {
        getAllProviders();
    }, []);
    const getAllProviders = () => {
        getData(Constants.END_POINT.PROVIDERS)
            .then((res) => {
                if (res.success) {
                    setProviders(res.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    // console.log("user",providers)

    const imageBodyTemplate = (rowData) => {
        return <img src={rowData.image ? Constants.BASE_URL + rowData.image : profile} alt={rowData.image} width={50} />;
    };
    const statusBodyTemplate2 = (rowData) => {
        return <span className={`product-badge status-${rowData.isVerified == "VERIFIED" ? "instock" : rowData.isVerified == "NOT_VERIFIED" ? "outofstock" : "pending"}`}>{rowData.isVerified == "VERIFIED" ? "Verified" : rowData.isVerified == "NOT_VERIFIED" ? "Unverified" : "Submited"}</span>;
    };

    const acceptFunc = (id) => {
        // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    };

    const reject = () => {
        // toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };

    const verifyFunc = (id, status) => {
        console.log("status", status);
        let payload = {
            isVerified: "VERIFIED",
        };
        postData(Constants.END_POINT.VERIFY_PROVIDER + id, payload).then((res) => {
            if (res.success) {
                toast.current.show({ severity: "info", summary: "", detail: "Provider verified Succesfully", life: 3000 });
                getAllProviders();
            }
        });
    };

    const deleteConfirm = (id) => {
        confirmDialog({
            message: "Do you want to delete this record?",
            header: "Delete Confirmation",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            rejectClassName: "cancel-button",
            accept: () => acceptFunc(id),
            reject,
        });
    };
    const ActionBodyTemplate = (rowData) => {
        console.log("rowData", rowData);
        return (
            <div className=" flex ">
                <Link to={`/provider-view/${rowData?._id}`}>
                    <Button type="button" className="p-buuton-raised p-button-rounded p-button-detail mx-2">
                        View
                    </Button>
                </Link>
                {/* <Button type="button" className={`p-button-raised p-button-rounded  p-button-outlined ${rowData?.isDeleted ? "p-button-success" : "p-button-danger"}`} onClick={() => blockUser(rowData?._id)}>
                    {rowData?.isDeleted ? "Unblock" : "Block"}
                </Button> */}
                <Button type="button" className={`p-button-raised p-button-rounded  p-button-outlined p-button-danger`} onClick={() => deleteConfirm(rowData?._id)}>
                    Delete
                </Button>
                <Button disabled={rowData?.isVerified === "VERIFIED"} type="button" className={`p-buuton-raised p-button-rounded p-button-detail mx-2`} onClick={() => verifyFunc(rowData?._id, rowData?.isVerified)}>
                    Verify
                </Button>
            </div>
        );
    };

    const nameTemplate = (rowData) => {
        return (
            <div>
                {rowData.firstName} {rowData.lastName}
            </div>
        );
    };

    const mobileTemplate = (rowData) => {
        return (
            <div>
                {rowData.countryCode} {rowData.mobile}
            </div>
        );
    };
    // get header
    const getHeader = () => {
        return (
            <div className="flex justify-content-end">
                <div className="p-input-icon-left">
                    <i className="pi pi-search"></i>
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} className="w-full" placeholder="Search" />
                </div>
            </div>
        );
    };

    return (
        <div className="col-12">
            <div className="card">
                <Toast ref={toast} />
                <h5>Providers</h5>
                <DataTable value={providers} header={getHeader} globalFilter={globalFilter} paginator rows={10} loading={loading} responsiveLayout="scroll">
                    {/* <Column expander style={{ width: "3em" }} /> */}
                    <Column field="firstName" header="Name" filter sortable body={nameTemplate} />
                    <Column field="email" header="Email" sortable />
                    <Column field="mobile" header="Mobile" sortable body={mobileTemplate} />
                    <Column header="Image" body={imageBodyTemplate} />
                    <Column field="isVerified" header="Status" sortable body={statusBodyTemplate2} />
                    <Column field="block" header="Action" body={ActionBodyTemplate} style={{ width: "10px" }} />
                </DataTable>
            </div>
        </div>
    );
};

export default Provider;
