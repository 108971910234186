import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useHistory } from "react-router-dom";
import { postData, postData1 } from "../../services/http.service";
import Constants from "../../services/constant";
import { Chips } from "primereact/chips";
import { InputTextarea } from "primereact/inputtextarea";

const CreateTermsCondition = () => {
    const toast = useRef(null);
    const history = useHistory();

    const [err, setErr] = useState({});
    const [termsCondition, setTermsCondition] = useState({
        heading: null,
        paragraphText: null,
    });

    const handleChangeTerms = (e) => {
        const { name, value } = e.target;
        setTermsCondition((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
        setErr({ ...err, [name]: "" });
    };

    console.log("termsCondition", termsCondition);

    const validateForm = () => {
        let errors = {};
        if (termsCondition.heading.length == 0) {
            errors.seasonName = "heading is required";
        }
        if (termsCondition.paragraphText.length == 0) {
            errors.seasonName = "terms and conditions is required";
        }

        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = validateForm();
        if (form) {
            postData(Constants.END_POINT.CREATE_TERM_CONDITION, termsCondition)
                .then((res) => {
                    if (res.success) {
                        console.log("res", res);
                        toast.current.show({ severity: "info", summary: "", detail: "Terms and Conditions has been Created Succesfully", life: 3000 });
                        setTimeout(() => history.push("/terms-conditions"), 1000);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    return (
        <div className="col-12">
            <div className="card">
                <div className="flex justify-content-between mb-4">
                    <Toast ref={toast} />
                    <h5> Create Terms And Conditions</h5>
                </div>

                <div className="p-fluid grid question-div-wrapper mt-4">
                    <div className="field col-12 md:col-12">
                        <span className="p-float-label">
                            <InputText id="heading" name="heading" onChange={handleChangeTerms} value={termsCondition.heading} />
                            <label htmlFor="heading">Heading</label>
                        </span>
                    </div>

                    <div className="field col-12 md:col-12">
                        <span className="p-float-label">
                            <InputTextarea id="paragraphText" name="paragraphText" onChange={handleChangeTerms} value={termsCondition.paragraphText} rows={5} cols={30} />
                            <label htmlFor="paragraphText">Terms and Conditions</label>
                        </span>
                    </div>
                </div>

                <div className="flex justify-content-left mt-3 ">
                    <Button type="button" className="p-button-md p-button-success" onClick={handleSubmit}>
                        Create Terms and Conditions
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default CreateTermsCondition;
