import React from "react";
import logo from "../src/assets/demo/flags/logo.png";

export const AppFooter = (props) => {
    return (
        <div className="layout-footer custom-footer">
            <div>{/* <img width='auto'  src={logo} alt="Logo" className="mr-2" /> */}</div>
            <span className="font-medium">Copyright © 2023 Pickers. All rights reserved.</span>
        </div>
    );
};
