import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { deleteData, getData } from "../../services/http.service";
import Constants from "../../services/constant";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { InputSwitch } from "primereact/inputswitch";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import dummy from "../../assets/images/dummy1.png";

const QuestionOptions = () => {
    const { id } = useParams();
    const toast = useRef(null);

    const [questionOption, setQuestionOption] = useState([]);
    const [loading, setLoading] = useState(true);

    const deleteQuestionOption = (id) => {
        deleteData(Constants.END_POINT.DELETE_QUESTION_OPTION + id).then((res) => {
            if (res.success) {
                toast.current.show({ severity: "info", summary: "", detail: "Question Option Deleted Succesfully", life: 3000 });
                getAllQuestionOption();
            }
        });
    };

    const getAllQuestionOption = () => {
        getData(Constants.END_POINT.ALL_QUESTION_OPTIONS + id)
            .then((res) => {
                if (res.success) {
                    console.log("resOption", res.data);
                    setQuestionOption(res.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const acceptFunc = (id) => {
        deleteQuestionOption(id);
        // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    };

    const reject = () => {
        // toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };

    const deleteConfirm = (id) => {
        confirmDialog({
            message: "Do you want to delete this record?",
            header: "Delete Confirmation",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            rejectClassName: "cancel-button",
            accept: () => acceptFunc(id),
            reject,
        });
    };

    const changeStatus = (id) => {
        getData(Constants.END_POINT.ENABLE_DISABLE_QUESTION_OPTION + id)
            .then((res) => {
                if (res.success) {
                    getAllQuestionOption();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const ActionBodyTemplate = (rowData) => {
        return (
            <div className="flex">
                <Link to={`/service-question-option-edit/${rowData?._id}`}>
                    <Button type="button" className="p-button-info mx-2">
                        Edit
                    </Button>
                </Link>

                <Button type="button" className="p-button-danger " onClick={() => deleteConfirm(rowData?._id)}>
                    Delete
                </Button>
                <Link to={`/load-option/${rowData?._id}`}>
                    <Button type="button" className="p-button-info mx-2">
                        Options
                    </Button>
                </Link>
            </div>
        );
    };

    const statusTemplate = (rowData) => {
        console.log("rowData", rowData.isActive);
        return (
            <div className="flex" style={{ alignItems: "center" }}>
                <InputSwitch checked={rowData?.isActive} onChange={(e) => changeStatus(rowData._id)} />
                {rowData?.isActive ? <div className="status-button status-active">Active</div> : <div className="status-button status-inactive">InActive</div>}
            </div>
        );
    };

    const ImageTemplate = (rowData) => {
        return <img src={rowData?.image ? Constants.BASE_URL + rowData?.image : dummy} style={{ width: "70px", height: "70px" }} />;
    };

    useEffect(() => {
        getAllQuestionOption();
    }, []);
    return (
        <div className="col-12">
            <div className="card">
                <div className="md:flex justify-content-between mb-4">
                    <Toast ref={toast} />
                    <h5>Question Options</h5>
                    <div className="flex justify-content-end mr-3">
                        <Link to={`/create-service-question-option/${id}`}>
                            <Button className="p-button-info" icon="pi pi-plus" label="Create Question Option" />
                        </Link>
                        {/* <Button label="Export" icon="pi pi-upload" className="p-button-help ml-3" onClick={() => ExportCSV(exportData)} /> */}
                    </div>
                </div>
                <DataTable value={questionOption} paginator rows={10} loading={loading} responsiveLayout="scroll">
                    <Column field="optionName" header="Option Name" sortable />
                    <Column field="image" header="Image" sortable body={ImageTemplate} />
                    <Column field="price" header="Price" sortable />
                    <Column field="status" header="Status" sortable body={statusTemplate} />
                    <Column field="block" header="Action" body={ActionBodyTemplate} style={{ width: "10px" }} />
                </DataTable>
            </div>
        </div>
    );
};

export default QuestionOptions;
