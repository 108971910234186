import React, { useState, useEffect, useRef } from "react";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { Chart } from "primereact/chart";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProductService } from "../service/ProductService";
import Constants from "../services/constant";
import { getData, postData } from "../services/http.service";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { convertTime24to12 } from "../utils";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";

const Dashboard = (props) => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [count, setCounts] = useState();

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue("--text-color");
        const textColorSecondary = documentStyle.getPropertyValue("--text-color-secondary");
        const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
        const data = {
            labels: ["January", "February", "March", "April", "May", "June", "July"],
            datasets: [
                {
                    label: "First Dataset",
                    data: [65, 59, 80, 81, 56, 55, 40],
                    fill: false,
                    borderColor: documentStyle.getPropertyValue("--blue-500"),
                    tension: 0.4,
                },
                {
                    label: "Second Dataset",
                    data: [28, 48, 40, 19, 86, 27, 90],
                    fill: false,
                    borderColor: documentStyle.getPropertyValue("--pink-500"),
                    tension: 0.4,
                },
            ],
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: textColor,
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                },
                y: {
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                },
            },
        };

        setChartData(data);
        setChartOptions(options);
    }, []);
    const [data, setData] = useState({
        title: "",
        description: "",
        recipient: "",
    });
    const [err, setErr] = useState({});
    const toast = useRef(null);
    const history = useHistory();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });

        setErr({ ...err, [name]: "" });
    };

    const validateForm = () => {
        let errors = {};
        if (!data.title) {
            errors.title = "Title is required";
        }
        if (!data.description) {
            errors.description = "Description is required";
        }
        if (!data.recipient) {
            errors.recipient = "Recipient is required";
        }
        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const form = validateForm();
        if (form) {
            let payload = {
                title: data.description,
                description: data.description,
                recipient: data.recipient,
            };
            postData(Constants.END_POINT.SEND_NOTIFICATION, payload)
                .then((res) => {
                    if (res.success) {
                        toast.current.show({ severity: "success", summary: res.message });
                        setData({
                            title: "",
                            description: "",
                            recipient: "",
                        });
                    }
                })
                .catch((err) => {
                    toast.current.show({ severity: "error", summary: err.message });
                });
        } else {
            window.scrollTo({
                top: 250,
                left: 0,
                behavior: "smooth",
            });
        }
    };
    const allRecipient = [
        { name: "User", value: "USER" },
        { name: "Provider", value: "PROVIDER" },
        { name: "Both", value: "BOTH" },
    ];
    useEffect(() => {
        getCounts();
        // getAllBokings();
    }, []);

    const getCounts = () => {
        getData(Constants.END_POINT.GET_COUNT)
            .then((res) => {
                if (res.success) {
                    setCounts(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <div>
            <Toast ref={toast} />
            <div className="grid justify-content-between">
                <div className="col-12 lg:col-6 xl:col-3" onClick={() => history.push("/users")}>
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">Users</span>
                                <div className="text-900 font-medium text-xl">{count?.user ? count?.user : 0}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                <i className="pi pi-inbox text-cyan-500 text-xl" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-3" onClick={() => history.push("/providers")}>
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">Provider</span>
                                <div className="text-900 font-medium text-xl">{count?.provider ? count?.provider : 0}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                <i className="pi pi-inbox text-cyan-500 text-xl" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-6">
                    <div className="card mb-0">
                        <div className="mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">Personalize Message</span>
                            </div>
                            <span className="p-float-label mt-5">
                                <InputText id="title" name="title" onChange={handleChange} value={data?.title} className="w-full" />
                                <label htmlFor="title">Title</label>
                            </span>
                            <div className="text-danger">{err?.title}</div>
                            <span className="p-float-label mt-5">
                                <InputText id="description" name="description" onChange={handleChange} value={data?.description} className="w-full" />
                                <label htmlFor="description">Description</label>
                            </span>
                            <div className="text-danger">{err?.description}</div>
                            <div className="flex flex-column">
                                <span className="p-float-label mt-5">
                                    <Dropdown id="recipient" name="recipient" value={data?.recipient} onChange={handleChange} options={allRecipient} optionLabel="name" placeholder="" className="w-full" />
                                    <label htmlFor="recipient">Recipient</label>
                                </span>
                                <div className="text-danger">{err?.recipient}</div>
                            </div>
                            <Button type="button" className="mt-3" onClick={handleSubmit}>
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(Dashboard, comparisonFn);
