import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Constants from "../../services/constant";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { useHistory } from "react-router-dom";
import { getData, postData, postData1, putData, putData1 } from "../../services/http.service";
import { InputTextarea } from "primereact/inputtextarea";
import Cross from "../../assets/demo/flags/cross.png";
import moment from "moment/moment";
import { Image } from "primereact/image";
import profile from "../../assets/demo/flags/profile.png";
import { Chips } from "primereact/chips";
import { MultiSelect } from "primereact/multiselect";
import { Accordion, AccordionTab } from "primereact/accordion";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Messages } from "primereact/messages";
import { Link, useLocation } from "react-router-dom";

const ProviderView = () => {
    const { id } = useParams();
    const toast = useRef(null);
    const history = useHistory();

    const [providerImg, setProviderImg] = useState(null);
    const [providerView, setProviderView] = useState({});
    const [err, setErr] = useState({});
    const [loading, setLoading] = useState(true);
    const [bookings, setBookings] = useState();
    const [filterdate, setFilterDate] = useState({
        bookingsFrom: null,
        bookingsTo: null,
    });
    useEffect(() => {
        if (filterdate?.bookingsTo && filterdate?.bookingsTo) {
            getProviderBooking1();
        } else {
            getProviderBooking();
        }
    }, [filterdate]);
    useEffect(() => {
        getSingleProvider();
        getProviderBooking();
    }, []);

    function dateTemplate(date) {
        return date.day;
    }
    const handleChange = (name) => (event) => {
        let value = event.target.value;
        setFilterDate({ ...filterdate, [name]: value });
    };
    const bookingDateTemplate = (rowData) => {
        return moment(rowData?.bookingDate).format("DD-MM-YYYY");
    };
    let { bookingsFrom, bookingsTo } = filterdate;
    bookingsFrom = moment(new Date(bookingsFrom)).format("DD-MM-YYYY");
    bookingsTo = moment(new Date(bookingsTo)).format("DD-MM-YYYY");

    const getSingleProvider = () => {
        getData(Constants.END_POINT.PROVIDERS_VIEW + id)
            .then((res) => {
                if (res.success) {
                    // console.log("resss",res.data)
                    let obj = {
                        _id: res?.data?._id,
                        firstName: res?.data?.firstName,
                        lastName: res?.data?.lastName,
                        image: res?.data?.image,
                        email: res?.data?.email,
                        countryCode: res?.data?.countryCode,
                        mobile: res?.data?.mobile,
                        isVerified: res?.data?.isVerified,
                        services: res?.data?.services,
                        documents: res?.data?.documents,
                    };
                    setProviderView(obj);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const getProviderBooking = () => {
        postData(Constants.END_POINT.GET_PROVIDER_BOOKINGS + id)
            .then((res) => {
                if (res.success) {
                    setBookings(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const experienceTemplate = (rowData) => {
        return (
            <div>
                {rowData.experience.month} {rowData.experience.year}
            </div>
        );
    };
    const servicesTemplate = (rowData) => {
        return rowData?.service.serviceName;
        // return (
        //     <Link
        //         to={{
        //             pathname: `/providerInfo/${rowData?._id}`,
        //             state: {
        //                 booking: rowData?.business?.location,
        //             },
        //         }}
        //     >
        //         {rowData?.services.map((item, i) => (i ? ", " + item?.serviceName : item?.serviceName))}
        //     </Link>
        // );
    };
    const selectedTemplate = (rowData) => {
        return <div>{rowData.isSelected ? "Selected" : "Not Selected"}</div>;
    };
    const userBodyTemplate1 = (rowData) => {
        return (
            <Link
                to={{
                    pathname: `/user-view/${rowData?.user?._id}`,
                    // state: {
                    //     booking: rowData?.business?.location,
                    // },
                }}
            >
                {rowData?.user?.firstName}
            </Link>
        );
    };
    const statusbodyTemplate = (rowData) => {
        let statusClass = "";
        let statusText = "";

        switch (rowData.status) {
            case "PENDING":
                statusClass = "outofstock";
                statusText = "Pending";
                break;
            case "CONFIRMED":
                statusClass = "instock";
                statusText = "Confirmed";
                break;
            case "CANCELLED":
                statusClass = "outofstock";
                statusText = "Cancelled";
                break;
            case "IN_PROGRESS":
                statusClass = "lowstock";
                statusText = "In Progress";
                break;
            case "IN_REVIEW":
                statusClass = "lowstock";
                statusText = "In Review";
                break;
            case "CONCERN_RAISED":
                statusClass = "lowstock";
                statusText = "Concern Raised";
                break;
            case "COMPLETED":
                statusClass = "instock";
                statusText = "Completed";
                break;
            case "UNCONFIRMED":
                statusClass = "outofstock";
                statusText = "Unconfirmed";
                break;
            default:
                statusClass = "unknown";
                statusText = "Unknown";
        }

        return <span className={`product-badge status-${statusClass}`}>{statusText}</span>;
    };
    const getProviderBooking1 = () => {
        postData(Constants.END_POINT.GET_PROVIDER_BOOKINGS + id, { bookingsFrom: bookingsFrom, bookingsTo: bookingsTo })
            .then((res) => {
                if (res.success) {
                    setBookings(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    console.log("providerViewoo", providerView);

    const message = useRef();
    const verifyProvider = (status) => {
        postData(Constants.END_POINT.VERIFY_PROVIDER + providerView?._id, { isVerified: status })
            .then((res) => {
                const msg = res?.data?.isVerified;

                toast.current.show({ severity: "success", summary: ` Status ${msg === "VERIFIED" ? "Approved" : "Rejected"}`, detail: "verification status updated Successfully" });
                getProviderBooking();
            })
            .catch((err) => console.log(err));
    };
    return (
        <div className="grid">
            <div className="col-12 md:col-12 mx-auto">
                <Toast ref={toast} />
                <div className="card ">
                    {loading ? (
                        <div className="overlay-loading">
                            <div class="spinner-border text-dark" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : null}
                    <div className=" flex justify-content-center">
                        <h3>Provider Information</h3>
                        <div className="ml-4 align-right"></div>
                    </div>
                    {/* <img src={product} alt="" width="10%" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} /> */}
                    <div className="p-fluid grid">
                        <div className="field col-12 md:col-12">
                            <div className="weapon-image-wrapper mt-4">
                                <div className="weapon-image-div">
                                    <img src={providerImg ? URL.createObjectURL(providerImg) : providerView.image ? Constants.BASE_URL + providerView.image : profile} alt="" className="weaponImageTag" />
                                    {providerImg !== null ? (
                                        <div className="weapon-edit-div">
                                            <i onClick={() => setProviderImg(null)} className="fa-sharp fa-solid fa-trash delete"></i>
                                        </div>
                                    ) : null}
                                </div>

                                {/* <input
                                    type="file"
                                    id="image"
                                    name="image"
                                    onChange={(e) => {
                                        setProviderImg(e.target.files[0]);
                                        setErr({ ...err, image: "" });
                                    }}
                                    onClick={(event)=> { 
                                        event.currentTarget.value = null
                                   }}
                                    hidden
                                />
                                <label htmlFor="image" className="weaponImageLabel">
                                    Update User Image
                                </label> */}
                                {/* <div className="text-danger">{err.image}</div> */}
                            </div>
                            {/* <div className="text-danger">{err.firstName}</div> */}
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <InputText id="firstName" name="firstName" value={providerView.firstName} style={{ opacity: 1 }} disabled />
                                <label htmlFor="firstName">First Name</label>
                            </span>
                        </div>

                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <InputText id="lastName" name="lastName" value={providerView.lastName} style={{ opacity: 1 }} disabled />
                                <label htmlFor="lastName">Last Name</label>
                            </span>
                        </div>

                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <InputText id="email" name="email" value={providerView.email} style={{ opacity: 1 }} disabled />
                                <label htmlFor="email">Email</label>
                            </span>
                        </div>

                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <InputText id="countryCode" name="countryCode" value={providerView.countryCode} style={{ opacity: 1 }} disabled />
                                <label htmlFor="countryCode">Country Code</label>
                            </span>
                        </div>

                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <InputText id="mobile" name="mobile" value={providerView.mobile} style={{ opacity: 1 }} disabled />
                                <label htmlFor="mobile">Phone Number</label>
                            </span>
                        </div>

                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <InputText id="isVerified" name="isVerified" value={providerView.isVerified == "VERIFIED" ? "Verified" : providerView.isVerified == "NOT_VERIFIED" ? "Unverified" : "Submited"} style={{ opacity: 1 }} disabled />
                                <label htmlFor="isVerified">Status</label>
                            </span>
                        </div>
                    </div>
                </div>

                <Accordion dion multiple className="mt-3">
                    <AccordionTab header="Services">
                        {providerView?.services?.length > 0 ? (
                            <div className="">
                                <h3>Service Information </h3>
                                <Messages ref={message} />
                                <DataTable value={providerView.services} responsiveLayout="scroll">
                                    <Column field="experience" header="Experience" body={experienceTemplate}></Column>
                                    <Column field="serviceName" header="Service Name"></Column>
                                    <Column field="isSelected" header="Selected" body={selectedTemplate}></Column>
                                </DataTable>
                            </div>
                        ) : (
                            "No Service Found"
                        )}
                    </AccordionTab>

                    <AccordionTab header="Bookings">
                        {bookings?.length ? (
                            <>
                                <div className="flex mb-3">
                                    <div>
                                        <label className="font-semibold">From Date</label>
                                        <Calendar className="mx-2" name="bookingsFrom" value={filterdate?.bookingsFrom} onChange={handleChange("bookingsFrom")} dateTemplate={dateTemplate} />
                                    </div>
                                    <div>
                                        <label className="font-semibold">To Date</label>
                                        <Calendar className="mx-2" name="bookingsTo" value={filterdate?.bookingsTo} onChange={handleChange("bookingsTo")} dateTemplate={dateTemplate} />
                                    </div>
                                    <div className="mx-2 d-flex flex-column justify-content-end">
                                        {/* <label>.</label> */}
                                        <Button
                                            type="button"
                                            onClick={() =>
                                                setFilterDate({
                                                    bookingsFrom: null,
                                                    bookingsTo: null,
                                                })
                                            }
                                            // className="p-button-outlined  align-bottom"
                                        >
                                            Reset
                                        </Button>
                                    </div>
                                </div>

                                <DataTable value={bookings} responsiveLayout="scroll">
                                    <Column body={servicesTemplate} header="Services"></Column>
                                    <Column body={userBodyTemplate1} header="User"></Column>
                                    <Column body={bookingDateTemplate} header="Booking Date"></Column>
                                    {/* <Column body={startTime} header="Start Time"></Column> */}
                                    {/* <Column field="duration" header="Duration"></Column> */}
                                    <Column body={statusbodyTemplate} header="Status"></Column>
                                </DataTable>
                            </>
                        ) : (
                            "No Bookings Found"
                        )}
                    </AccordionTab>
                    <AccordionTab header="Documents">
                        {providerView?.documents ? (
                            <>
                                <div className="md:flex">
                                    <h3>Documents </h3>
                                    {providerView?.isVerified !== "VERIFIED" ? (
                                        <>
                                            <div className="ml-4">
                                                <Button
                                                    type="button"
                                                    className="p-button-raised p-button-rounded  p-button-outlined p-button-info"
                                                    onClick={() => {
                                                        verifyProvider("VERIFIED");
                                                    }}
                                                >
                                                    Approve
                                                </Button>
                                            </div>
                                            <div>
                                                <Button
                                                    type="button"
                                                    className="p-button-raised p-button-rounded  p-button-outlined p-button-danger ml-2"
                                                    onClick={() => {
                                                        verifyProvider("REJECTED");
                                                        getSingleProvider();
                                                    }}
                                                >
                                                    Reject
                                                </Button>
                                            </div>
                                        </>
                                    ) : null}
                                </div>

                                <div>
                                    <div className="card my-4 text-center">
                                        <div className="flex justify-content-around">
                                            <div>
                                                {/* <div>Front</div> */}
                                                {providerView?.documents?.documentFiles.map((image) => (
                                                    <Image src={Constants?.BASE_URL + image} alt="galleria" width="150px" preview />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            "No Documents Found"
                        )}
                    </AccordionTab>
                </Accordion>
            </div>
        </div>
    );
};

export default ProviderView;
