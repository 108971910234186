import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useHistory } from "react-router-dom";
import { postData, postData1 } from "../../services/http.service";
import Constants from "../../services/constant";
import { Chips } from "primereact/chips";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import service_dummy from "../../assets/demo/flags/service-dummy.svg";
import { Calendar } from "primereact/calendar";

const CreateLoadOption = () => {
    const { id } = useParams();
    const toast = useRef(null);
    const history = useHistory();

    const [err, setErr] = useState({});
    const [optionImg, setOptionImg] = useState(null);
    const [loadOption, setLoadOption] = useState({
        name:"",
        optionName: "",
        loadTime:"",
        optionId: id,
    });

    console.log("loadOption", loadOption);
    console.log("optionImg", optionImg);

    const validateForm = () => {
        let errors = {};
        if (loadOption.optionName.length == 0) {
            errors.optionName = "Option Name is required";
        }
        if (loadOption.name.length == 0) {
            errors.name = "Name is required";
        }
        if (loadOption.loadTime.length == 0) {
            errors.loadTime = "Load Time is required";
        }
        if (!optionImg) {
            errors.image = "Image is required";
        }

        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleChangeOption = (e) => {
        const { name, value } = e.target;
            setLoadOption((prev) => {
                return {
                    ...prev,
                    [name]: value,
                };
            });
            setErr({ ...err, [name]: "" });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = validateForm();
        if (form) {
            const formData = new FormData();
            formData.append("serviceImage", optionImg);
            postData1(Constants.END_POINT.UPLOAD_IMAGE, formData)
            .then((res) => {
                if (res.success) {
                    let payload = {
                        ...loadOption,
                        image:res.data.image,
                        loadTime:loadOption?.loadTime?.getHours() * 60 + loadOption?.loadTime?.getMinutes(),
                    };
                    postData(Constants.END_POINT.CREATE_LOAD_OPTION, payload)
                    .then((res) => {
                        if (res.success) {
                            console.log("res", res);
                            toast.current.show({ severity: "info", summary: "", detail: "Option has been Created Succesfully", life: 3000 });
                            setTimeout(() => history.push(`/load-option/${id}`), 1000);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
    };

    return (
        <div className="grid">
            <div className="col-12 md:col-12 mx-auto">
                <Toast ref={toast} />
                <div className="card ">
                    <div className=" flex justify-content-left">
                        <h3>Add Option</h3>
                        <div className="ml-4 align-right"></div>
                    </div>
                    <div className="p-fluid grid question-div-wrapper mt-4">
                        <div className="field weapon-image-wrapper mt-4 col-12">
                            <div className="weapon-image-div">
                                <img src={optionImg ? URL.createObjectURL(optionImg) : service_dummy} alt="" className="weaponImageTag" />
                                {optionImg !== null ? (
                                    <div className="weapon-edit-div">
                                        <i onClick={() => setOptionImg(null)} className="fa-sharp fa-solid fa-trash delete"></i>
                                    </div>
                                ) : null}
                            </div>

                            <input
                                type="file"
                                id="image"
                                name="image"
                                onChange={(e) => {
                                    setOptionImg(e.target.files[0]);
                                    setErr({ ...err, image: "" });
                                }}
                                onClick={(event) => {
                                    event.currentTarget.value = null;
                                }}
                                hidden
                            />
                            <label htmlFor="image" className="weaponImageLabel">
                                Add Image
                            </label>
                            <div className="text-danger">{err.image}</div>
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label">
                                <InputText id="name" name="name" onChange={handleChangeOption} value={loadOption.name} />
                                <label htmlFor="name">Name</label>
                            </span>
                            <div className="text-danger">{err.name}</div>
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label">
                                <InputText id="optionName" name="optionName" onChange={handleChangeOption} value={loadOption.optionName} />
                                <label htmlFor="optionName">Option Name</label>
                            </span>
                            <div className="text-danger">{err.optionName}</div>
                        </div>
                        {/* <div className="field col-12 md:col-6">
                            <span className="p-float-label">
                                <InputText id="loadTime" name="loadTime" onChange={handleChangeOption} value={loadOption.loadTime} />
                                <label htmlFor="loadTime">Load Time</label>
                            </span>
                            <div className="text-danger">{err.loadTime}</div>
                        </div> */}
                         <div className="field col-12 md:col-6">
                        <span className="p-float-label">
                        <Calendar id="loadTime" name="loadTime" value={loadOption.loadTime} onChange={handleChangeOption} showTime timeOnly />
                        <label htmlFor="loadTime">Load Time</label>
                                </span>
                                <div className="text-danger">{err.loadTime}</div>
                        </div>

                    </div>
                    <div className="flex justify-content-left mt-3 ">
                        {
                            <Button type="button" className="p-button-md p-button-success" onClick={handleSubmit}>
                                Add Option
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateLoadOption;
