import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useHistory } from "react-router-dom";
import { getData, postData, postData1, putData } from "../../services/http.service";
import Constants from "../../services/constant";
import { Chips } from "primereact/chips";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { InputTextarea } from "primereact/inputtextarea";

const ServiceQuestionEdit = () => {
    const {id} = useParams()
    const toast = useRef(null);
    const history = useHistory();

    const [err, setErr] = useState({});
    const [serviceQuestions, setServiceQuestions] = useState({
        questionName:'',
        serviceId: id,
        questionType:'',
        selectType:'',
        questionDetail:''
    });
    const [loading, setLoading] = useState(true);

    console.log("testinggggg",serviceQuestions)

const questionTypeOption = [
    {name:'Range',value:'RANGE'},
    {name:'Selection',value:'SELECTION'},
    {name:'Input',value:'INPUT'},
]

const selectTypeOption = [
    {name:'Single',value:'SINGLE'},
    {name:'Multiple',value:'MULTIPLE'},
]

const handleChangeQuestion = (e) => {
const {name, value} = e.target
setServiceQuestions((prev)=>{
    return {
        ...prev,
        [name]:value
    }
})

setErr({ ...err, [name]: "" })
}

    const validateForm = () => {
        let errors = {};
        if (serviceQuestions.questionName.length==0) {
            errors.questionName = "Questions Name is required";
        }
        if (serviceQuestions.questionType.length==0) {
            errors.questionType = "Question Type is required";
        }
        if (serviceQuestions.selectType.length==0) {
            errors.selectType = "Select Type is required";
        }
        if (serviceQuestions.questionDetail.length==0) {
            errors.questionDetail = "Question Details is required";
        }

        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = validateForm();
        if (form) {
            putData(Constants.END_POINT.UPDATE_SERVICE_QUESTION + id,serviceQuestions)
            .then((res) => {
                if (res.success) {
                    toast.current.show({ severity: "info", summary: "", detail: "Service Questions has been Updated Succesfully", life: 3000 });
                    console.log("id",id)
                    setTimeout(()=>history.goBack(), 1000)
                    ;
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
    }


    useEffect(() => {
        getSingleServiceQuestion();
    }, []);

    const getSingleServiceQuestion = () => {
        getData(Constants.END_POINT.EDIT_SERVICE_QUESTION + id)
            .then((res) => {
                if (res.success) {
                    setServiceQuestions(res.data)
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div className="grid">
            <div className="col-12 md:col-12 mx-auto">
                <Toast ref={toast} />
                <div className="card ">
                {loading ? 
                    <div className="overlay-loading">
                    <div class="spinner-border text-dark" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                : null}
                    <div className=" flex justify-content-center">
                        <h3>Edit Service Question</h3>
                        <div className="ml-4 align-right"></div>
                    </div>
                    <div className="p-fluid grid question-div-wrapper mt-4">
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label">
                                <InputText id="questionName" name="questionName" onChange={handleChangeQuestion} value={serviceQuestions.questionName} />
                                <label htmlFor="questionName">Question Name</label>
                            </span>
                            <div className="text-danger">{err.questionName}</div>
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label">
                            <Dropdown id="questionType" name="questionType" value={serviceQuestions.questionType} onChange={handleChangeQuestion} options={questionTypeOption} optionLabel="name" 
                placeholder="" className="w-full" />
                                <label htmlFor="questionType">Question Type</label>
                            </span>
                            <div className="text-danger">{err.questionType}</div>
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label">
                            <Dropdown id="selectType" name="selectType" value={serviceQuestions.selectType} onChange={handleChangeQuestion} options={selectTypeOption} optionLabel="name" 
                placeholder="" className="w-full" />
                                <label htmlFor="selectType">Select Type</label>
                            </span>
                            <div className="text-danger">{err.selectType}</div>
                        </div>
                        <div className="field col-12 md:col-12">
                            <span className="p-float-label">
                                {/* <InputText id="questionDetail" name="questionDetail" onChange={(e)=>{setServiceQuestions(e.target.value);setErr({ ...err, questionName: "" });}} value={serviceQuestions.questionDetail} /> */}
                                <InputTextarea id="questionDetail" name="questionDetail" onChange={handleChangeQuestion} value={serviceQuestions.questionDetail} rows={5} cols={30} />
                                <label htmlFor="questionDetail">Question Detail</label>
                            </span>
                            <div className="text-danger">{err.questionDetail}</div>
                        </div>
                        

                    </div>
                    <div className="flex justify-content-center mt-3 ">
                        {
                            <Button type="button" className="p-button-md p-button-success" onClick={handleSubmit}>
                                Update serviceQuestions
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceQuestionEdit;
