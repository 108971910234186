import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useHistory } from "react-router-dom";
import { postData, postData1 } from "../../services/http.service";
import Constants from "../../services/constant";
import { Chips } from "primereact/chips";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import service_dummy from "../../assets/demo/flags/service-dummy.svg";
import { Calendar } from "primereact/calendar";
import moment from 'moment';

const CreateQuestionOption = () => {
    const { id } = useParams();
    const toast = useRef(null);
    const history = useHistory();

    const [err, setErr] = useState({});
    const [optionImg, setOptionImg] = useState(null);
    // const [initial,setInitial] = useState(false)
    const [questionOption, setQuestionOption] = useState({
        optionName: "",
        price: "",
        image: "",
        barTime: {
            min: "",
            max: "",
        },
        optionType: "",
        selectType: "",
        questionId: id,
    });

    // const hours = Array.from({ length: 24 }, (_, index) => String(index).padStart(2, "0"));
    // const minutes = Array.from({ length: 60 }, (_, index) => String(index).padStart(2, "0"));

    console.log("questionOption", questionOption);
    console.log("optionImg", optionImg);

    const optionTypeOption = [
        { name: "Range", value: "RANGE" },
        { name: "Selection", value: "SELECTION" },
        { name: "Input", value: "INPUT" },
        { name: "Location", value: "LOCATION" },
    ];

    const selectTypeOption = [
        { name: "Single", value: "SINGLE" },
        { name: "Multiple", value: "MULTIPLE" },
    ];

    const validateForm = () => {
        let errors = {};
        if (questionOption.optionName.length == 0) {
            errors.optionName = "Option Name is required";
        }
        if (questionOption.price.length == 0) {
            errors.price = "Price is required";
        }
        if (questionOption.barTime.min.length == 0) {
            errors.min = "Bar Time minimum is required";
        }
        if (questionOption.barTime.max.length == 0) {
            errors.max = "Bar Time maximum is required";
        }
        if (questionOption.selectType.length == 0) {
            errors.selectType = "Select Type is required";
        }
        if (questionOption.optionType.length == 0) {
            errors.optionType = "Option Type is required";
        }
        if (!optionImg) {
            errors.image = "Image is required";
        }

        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleChangeOption = ({ name, value }) => {
        // const { name, value } = e.target;
        console.log("name",name,value)
        if (name == "min") {
            setQuestionOption((prev) => {
                return {
                    ...prev,
                    barTime: {
                        ...prev.barTime,
                        min: value,
                    },
                };
            });
            setErr({ ...err, [name]: "" });
        } else if (name == "max") {
           console.log("testingg",moment(value).isBefore(questionOption.barTime.min))
            setQuestionOption((prev) => {
                return {
                    ...prev,
                    barTime: {
                        ...prev.barTime,
                        max: value,
                    },
                };
            });
            setErr({ ...err, [name]: "" });
        } else {
            setQuestionOption((prev) => {
                return {
                    ...prev,
                    [name]: value,
                };
            });
            setErr({ ...err, [name]: "" });
        }
    };

    const setMinValue = () => {
        // if(initial==false){
            setQuestionOption((prev) => {
                return {
                    ...prev,
                    barTime: {
                        ...prev.barTime,
                        max: new Date(questionOption.barTime.min),
                    },
                };
            });
            // setInitial(true)
        // }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = validateForm();
        if (form) {
            const formData = new FormData();
            formData.append("serviceImage", optionImg);
            postData1(Constants.END_POINT.UPLOAD_IMAGE, formData)
                .then((res) => {
                    if (res.success) {
                        let payload = {
                            ...questionOption,
                            image:res.data.image,
                            barTime: {
                                min: questionOption?.barTime?.min?.getHours() * 60 + questionOption?.barTime?.min?.getMinutes(),
                                max: questionOption?.barTime?.max?.getHours() * 60 + questionOption?.barTime?.max?.getMinutes(),
                            },
                        };
                        postData(Constants.END_POINT.CREATE_QUESTION_OPTION, payload)
                            .then((res) => {
                                if (res.success) {
                                    console.log("res", res);
                                    toast.current.show({ severity: "info", summary: "", detail: "Question Option has been Created Succesfully", life: 3000 });
                                    setTimeout(() => history.push(`/service-questions-option/${id}`), 1000);
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    return (
        <div className="grid">
            <div className="col-12 md:col-12 mx-auto">
                <Toast ref={toast} />
                <div className="card ">
                    <div className=" flex justify-content-left">
                        <h3>Add Question Option</h3>
                        <div className="ml-4 align-right"></div>
                    </div>
                    <div className="p-fluid grid question-div-wrapper mt-4">
                        <div className="field weapon-image-wrapper mt-4 col-12">
                            <div className="weapon-image-div">
                                <img src={optionImg ? URL.createObjectURL(optionImg) : service_dummy} alt="" className="weaponImageTag" />
                                {optionImg !== null ? (
                                    <div className="weapon-edit-div">
                                        <i onClick={() => setOptionImg(null)} className="fa-sharp fa-solid fa-trash delete"></i>
                                    </div>
                                ) : null}
                            </div>

                            <input
                                type="file"
                                id="image"
                                name="image"
                                onChange={(e) => {
                                    setOptionImg(e.target.files[0]);
                                    setErr({ ...err, image: "" });
                                }}
                                onClick={(event) => {
                                    event.currentTarget.value = null;
                                }}
                                hidden
                            />
                            <label htmlFor="image" className="weaponImageLabel">
                                Add Image
                            </label>
                            <div className="text-danger">{err.image}</div>
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label">
                                <InputText id="optionName" name="optionName" onChange={(e) => handleChangeOption({ name: e.target.name, value: e.target.value })} value={questionOption.optionName} />
                                <label htmlFor="optionName">Option Name</label>
                            </span>
                            <div className="text-danger">{err.optionName}</div>
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label">
                                <InputText id="price" name="price" keyfilter="num" onChange={(e) => handleChangeOption({ name: e.target.name, value: e.target.value })} value={questionOption.price} />
                                <label htmlFor="price">Price</label>
                            </span>
                            <div className="text-danger">{err.price}</div>
                        </div>
                        <div className="field col-12 md:col-6">
                        <div className="flex">
                        <span className="p-float-label w-full">
                        <Calendar id="hours" name="min" value={questionOption?.barTime?.min} onChange={(e) => handleChangeOption({ name: e.target.name, value: e.value })} showTime timeOnly />
                                    <label htmlFor="hours">Bar Time Minimum hours</label>
                                </span>
                        </div>
                            <div className="text-danger">{err.min}</div>
                        </div>
                        <div className="field col-12 md:col-6">
                        <div className="flex">
                        <span className="p-float-label w-full">
                        <Calendar id="hoursMax" name="max" value={questionOption?.barTime?.max} onShow={()=>setMinValue()} onChange={(e) => handleChangeOption({ name: e.target.name, value: e.value })} timeOnly showTime minDate={new Date(questionOption?.barTime?.min)}/>
                                    <label htmlFor="hoursMax">Bar Time Maximum hours</label>
                                </span>
                        </div>

                            <div className="text-danger">{err.max}</div>
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label">
                                <Dropdown id="optionType" name="optionType" value={questionOption.optionType} onChange={(e) => handleChangeOption({ name: e.target.name, value: e.target.value })} options={optionTypeOption} optionLabel="name" placeholder="" className="w-full" />
                                <label htmlFor="optionType">Option Type</label>
                            </span>
                            <div className="text-danger">{err.optionType}</div>
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label">
                                <Dropdown id="selectType" name="selectType" value={questionOption.selectType} onChange={(e) => handleChangeOption({ name: e.target.name, value: e.target.value })} options={selectTypeOption} optionLabel="name" placeholder="" className="w-full" />
                                <label htmlFor="selectType">Select Type</label>
                            </span>
                            <div className="text-danger">{err.selectType}</div>
                        </div>
                    </div>
                    <div className="flex justify-content-left mt-3 ">
                        {
                            <Button type="button" className="p-button-md p-button-success" onClick={handleSubmit}>
                                Add Question Option
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateQuestionOption;
