export default class Constants {
    // static BASE_URL = "http://192.168.1.34:3030/";
    // SOCKET_URL = "http://192.168.1.34:3030/";
    static SOCKET_URL = "https://pickerapi.appdeft.biz/";
    static BASE_URL = "https://pickerapi.appdeft.biz/";
    static END_POINT = {
        SIGIN: "api/admin/login",
        USERS: "api/admin/users",
        USERS_VIEW: "api/admin/user/",
        PROVIDERS: "api/admin/providers/",
        PROVIDERS_VIEW: "api/admin/provider/",
        VERIFY_PROVIDER: "api/admin/verify-provider/",
        GET_ADMIN_PROFILE: "api/admin/profile",
        UPDATE_ADMIN_PROFILE: "api/admin/update",
        CHANGE_PASSWORD: "api/admin/change-password",
        FORGOT_PASSWORD: "api/admin/forgot-password",
        RESET_PASSWORD: "api/admin/change-forget-password",
        UPLOAD_IMAGE: "api/admin/upload-service-image",
        GET_ADMIN_NOTIFICATIONS: "api/admin/notifications",
        GET_ADMIN_NOTIFI_COUNT: "api/admin/notification-count",
        // chat url //
        GETDIALOGS: "api/admin/get-dialogs",
        GETOLDCHAT: "api/admin/admin-old-chat",
        SENDMSG: "api/admin/send-massage",
        CREATE_ROOM: "api/admin/create-room",
        //
        ALL_SEASONS: "api/admin/seasons/",
        EDIT_SEASON: "api/admin/season/",
        CREATE_SEASON: "api/admin/create-season",
        UPDATE_SEASON: "api/admin/update-season/",
        ENABLE_DISABLE_SEASON: "api/admin/enable-disable-season/",
        DELETE_SEASON: "api/admin/delete-season/",
        ALL_SERVICES: "api/admin/services/",
        DELETE_SERVICE: "api/admin/delete-service/",
        ENABLE_DISABLE_SERVICE: "api/admin/enable-disable-service/",
        CREATE_SERVICE: "api/admin/create-service/",
        EDIT_SERVICE: "api/admin/service/",
        UPDATE_SERVICE: "api/admin/update-service/",
        ALL_SERVICE_QUESTIONS: "api/admin/service-questions/",
        CREATE_SERVICE_QUESTION: "api/admin/create-service-question",
        EDIT_SERVICE_QUESTION: "api/admin/service-question/",
        UPDATE_SERVICE_QUESTION: "api/admin/update-service-question/",
        ENABLE_DISABLE_QUESTION: "api/admin/enable-disable-service-question/",
        DELETE_SERVICE_QUESTION: "api/admin/delete-service-question/",
        ALL_QUESTION_OPTIONS: "api/admin/question-options/",
        CREATE_QUESTION_OPTION: "api/admin/create-question-option/",
        EDIT_QUESTION_OPTION: "api/admin/question-option/",
        UPDATE_QUESTION_OPTION: "api/admin/update-question-option/",
        ENABLE_DISABLE_QUESTION_OPTION: "api/admin/enable-disable-option/",
        DELETE_QUESTION_OPTION: "api/admin/delete-question-option/",
        GET_TERMS_CONDITION: "api/admin/term-and-condition",
        UPDATE_TERM_CONDITION: "api/admin/update-term-and-condition/",
        DELETE_TERM_CONDITION: "api/admin/delete-term-and-condition/",
        CREATE_TERM_CONDITION: "api/admin/create-term-and-condition/",
        ALL_SERVICE_LOCATION: "api/admin/all-service-locations/",
        SERVICE_LOCATION_ENABLE: "api/admin/enable-disable-service-location/",
        DELETE_LOCATION: "api/admin/delete-service-location/",
        CREATE_SERVICE_LOCATION: "api/admin/create-service-location/",
        EDIT_LOCATION: "api/admin/service-location/",
        UPDATE_LOCATION: "api/admin/update-service-location/",
        DELETE_REASON: "api/admin/delete-reason/",
        ALL_REASONS: "api/admin/reasons/",
        ENABLE_REASON: "api/admin/enable-disable-reason/",
        CREATE_REASON: "api/admin/create-reason/",
        EDIT_REASON: "api/admin/reason/",
        UPDATE_REASON: "api/admin/update-reason/",
        ALL_LOAD_OPTION: "api/admin/load-options/",
        CREATE_LOAD_OPTION: "api/admin/create-load-option/",
        UPDATE_LOAD_OPTION: "api/admin/update-load-option/",
        EDIT_LOAD_OPTION: "api/admin/load-option/",
        DELETE_LOAD_OPTION: "api/admin/delete-load-option/",
        ENABLE_DISABLE_LOAD_OPTION: "api/admin/enable-disable-load-option/",

        GET_COUNT: "api/admin/get-count",
        ALL_BOOKINGS: "api/admin/all-bookings",
        RELEASE_BOOKING: "api/admin/release-booking/",

        GET_USER_BOOKINGS: "api/admin/user/bookings/",
        GET_PROVIDER_BOOKINGS: "api/admin/provider/bookings/",
        SEND_NOTIFICATION: "api/admin/notification",
    };
}
