import React, { useEffect, useState, useRef } from "react";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import { getData, postData } from "../services/http.service";
import Constants from "../services/constant";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { convertTime24to12 } from "../utils";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Bookings = () => {
    const [globalFilter, setGlobalFilter] = useState("");
    const [bookings, setBooking] = useState();
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const [filterdate, setFilterDate] = useState({
        bookingsFrom: null,
        bookingsTo: null,
    });
    let { bookingsFrom, bookingsTo } = filterdate;
    bookingsFrom = moment(new Date(bookingsFrom)).format("DD-MM-YYYY");
    bookingsTo = moment(new Date(bookingsTo)).format("DD-MM-YYYY");

    useEffect(() => {
        getAllBokings();
    }, []);
    useEffect(() => {
        if (filterdate?.bookingsTo) {
            getAllBokings1();
        }
    }, [filterdate]);

    console.log(filterdate, "filterDate");
    const getAllBokings = () => {
        postData(Constants.END_POINT.ALL_BOOKINGS)
            .then((res) => {
                if (res.success) {
                    setBooking(res.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const getAllBokings1 = () => {
        postData(Constants.END_POINT.ALL_BOOKINGS, { bookingsFrom: bookingsFrom, bookingsTo: bookingsTo })
            .then((res) => {
                if (res.success) {
                    setBooking(res.data);
                }
            })
            .catch((err) => console.log(err));
    };

    const releaseJob = (id) => {
        getData(Constants.END_POINT.RELEASE_BOOKING + id)
            .then((res) => {
                if (res.success) {
                    toast.current.show({ severity: "info", summary: "Confirmed", detail: "Job has been successfully released.", life: 3000 });
                }
                getAllBokings();
            })
            .catch((err) => console.log(err));
    };
    const toast = useRef(null);
    const reject = () => {
        toast.current.show({ severity: "warn", summary: "Rejected", detail: "You have rejected", life: 3000 });
    };

    const releaseConfirm = (id) => {
        confirmDialog({
            message: "Are you sure you want to release this job? This action cannot be undone.",
            header: "Release Confirmation",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            rejectClassName: "cancel-button",
            accept: () => releaseJob(id),
            reject,
        });
    };
    const servicesTemplate = (rowData) => {
        return rowData?.service.serviceName;
        // return (
        //     <Link
        //         to={{
        //             pathname: `/providerInfo/${rowData?._id}`,
        //             state: {
        //                 booking: rowData?.business?.location,
        //             },
        //         }}
        //     >
        //         {rowData?.services.map((item, i) => (i ? ", " + item?.serviceName : item?.serviceName))}
        //     </Link>
        // );
    };
    const providerTemplate = (rowData) => {
        // return rowData?.provider?.firstName;
        return (
            <Link
                to={{
                    pathname: `/provider-view/${rowData?.provider?._id}`,
                    // state: {
                    //     booking: rowData?.business?.location,
                    // },
                }}
            >
                {rowData?.provider?.firstName}
            </Link>
        );
    };
    const userBodyTemplate1 = (rowData) => {
        return (
            <Link
                to={{
                    pathname: `/user-view/${rowData?.user?._id}`,
                    // state: {
                    //     booking: rowData?.business?.location,
                    // },
                }}
            >
                {rowData?.user?.firstName}
            </Link>
        );
    };
    const bookingDateTemplate = (rowData) => {
        console.log("rowData===booking", rowData);
        let bookingDate = moment(rowData?.bookingDate).format("MM-DD-YYYY");
        let start = moment(rowData?.bookingTime?.startTime).format("HH:mm");
        // start = convertTime24to12(start);
        return bookingDate;
    };
    const startTime = (rowData) => {
        let start = moment(rowData?.bookingTime?.startTime).format("HH:mm");
        return convertTime24to12(start);
    };
    const endTime = (rowData) => {
        let start = moment(rowData?.bookingTime?.endTime).format("HH:mm");
        return convertTime24to12(start);
    };
    const statusbodyTemplate = (rowData) => {
        let statusClass = "";
        let statusText = "";

        switch (rowData.status) {
            case "PENDING":
                statusClass = "outofstock";
                statusText = "Pending";
                break;
            case "CONFIRMED":
                statusClass = "instock";
                statusText = "Confirmed";
                break;
            case "CANCELLED":
                statusClass = "outofstock";
                statusText = "Cancelled";
                break;
            case "IN_PROGRESS":
                statusClass = "lowstock";
                statusText = "In Progress";
                break;
            case "IN_REVIEW":
                statusClass = "lowstock";
                statusText = "In Review";
                break;
            case "CONCERN_RAISED":
                statusClass = "lowstock";
                statusText = "Concern Raised";
                break;
            case "COMPLETED":
                statusClass = "instock";
                statusText = "Completed";
                break;
            case "UNCONFIRMED":
                statusClass = "outofstock";
                statusText = "Unconfirmed";
                break;
            default:
                statusClass = "unknown";
                statusText = "Unknown";
        }
        return <span className={`product-badge status-${statusClass}`}>{statusText}</span>;
        // return <span className={`product-badge status-${rowData.status === "CONFIRMED" ? "instock" : "outofstock"}`}>{rowData.status}</span>;
    };

    const actionTemplate = (rowData) => {
        console.log("rowData==<<<>>>", rowData);
        const showError = (message) => {
            toast.current.show({ severity: "error", summary: "Error", detail: message, life: 3000 });
        };

        const handleChatRedirect = () => {
            const userId = rowData?.user?._id;
            const serviceId = rowData?.service?._id;
            const providerId = rowData?.provider?._id;

            if (userId && serviceId && providerId) {
                history.push({
                    pathname: "/chat",
                    state: {
                        userId,
                        serviceId,
                        providerId,
                    },
                });
            } else {
                showError("Invalid user ID, service ID, or provider ID. Cannot redirect to chat.");
            }
        };
        return (
            <div className="flex">
                {rowData.status === "CONFIRMED" ? (
                    <>
                        <Button type="button" className={`p-button-raised p-button-rounded  p-button-outlined p-button-danger`} onClick={() => releaseConfirm(rowData?._id)}>
                            release Job
                        </Button>
                        <i className={`pi pi-fw pi-comments ml-4 cursor-pointer`} onClick={handleChatRedirect} style={{ fontSize: "2.5em" }}></i>
                    </>
                ) : (
                    "N/A"
                )}

                <Toast ref={toast} />
            </div>
        );
    };

    const handleChange = (name) => (event) => {
        let value = event.target.value;
        setFilterDate({ ...filterdate, [name]: value });
    };
    const dateTemplate = (date) => {
        return date.day;
    };

    const getHeader = () => {
        return (
            <div className="flex justify-content-end">
                <div className="p-input-icon-left">
                    <i className="pi pi-search"></i>
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} className="w-full" placeholder="Search" />
                </div>
            </div>
        );
    };
    console.log("Bookings==>", bookings);

    return (
        <div>
            <Toast ref={toast} />
            <div className="col-12 xl:col-12">
                <div className="card">
                    <h5>Bookings</h5>
                    <>
                        <div className="flex mb-3 align-items-end">
                            <div>
                                <label className="font-semibold">From </label>
                                <Calendar className="mx-2" placeholder="MM/DD/YYYY" value={filterdate?.bookingsFrom} onChange={handleChange("bookingsFrom")} name="bookingsFrom" dateTemplate={dateTemplate} />
                            </div>
                            <div>
                                <label className="font-semibold">To </label>
                                <Calendar className="mx-2" placeholder="MM/DD/YYYY" value={filterdate?.bookingsTo} name="bookingsTo" onChange={handleChange("bookingsTo")} dateTemplate={dateTemplate} />
                            </div>
                            <div>
                                <Button
                                    className=""
                                    onClick={() => {
                                        setFilterDate({ bookingsFrom: null, bookingsTo: null });
                                        getAllBokings();
                                    }}
                                >
                                    Reset{" "}
                                </Button>
                            </div>
                        </div>
                        <DataTable value={bookings} paginator header={getHeader} globalFilter={globalFilter} rows={10} loading={loading} responsiveLayout="scroll">
                            <Column field="service.serviceName" header="Services" sortable body={servicesTemplate} />
                            <Column field="provider.firstName" header="Provider" sortable body={providerTemplate} />
                            <Column field="user.firstName" header="User" sortable body={userBodyTemplate1} />
                            <Column field="bookingDate" header="Booking Date" sortable body={bookingDateTemplate} />
                            {/* <Column field="bookingTime.startTime" header="Start Time" body={startTime} /> */}
                            {/* <Column field="bookingTime.endTime" header="End Time" body={endTime} /> */}
                            <Column field="status" header="Status" body={statusbodyTemplate} />
                            <Column header="Action" body={actionTemplate} style={{ width: "25%" }} />
                        </DataTable>
                    </>
                </div>
            </div>
        </div>
    );
};

export default Bookings;
