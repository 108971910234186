import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useHistory } from "react-router-dom";
import { postData, postData1 } from "../../services/http.service";
import Constants from "../../services/constant";
import { Chips } from "primereact/chips";

const CreateServiceLocation = () => {
    const toast = useRef(null);
    const history = useHistory();

    const [err, setErr] = useState({});
    const [serviceLocation, setServiceLocation] = useState(null);

console.log("serviceLocation",serviceLocation)


    const validateForm = () => {
        let errors = {};
        if (!serviceLocation) {
            errors.locationName = "Location Name is required";
        }

        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = validateForm();
        if (form) {
          let  payload={
                locationName:serviceLocation
            }
            postData(Constants.END_POINT.CREATE_SERVICE_LOCATION, payload)
            .then((res) => {
                if (res.success) {
                    console.log("res", res);
                    toast.current.show({ severity: "info", summary: "", detail: "Service Location has been Created Succesfully", life: 3000 });
                    setTimeout(()=>history.push("/service-locations"), 1000)
                    ;
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
    }

    return (
        <div className="grid">
            <div className="col-12 md:col-12 mx-auto">
                <Toast ref={toast} />
                <div className="card ">
                    <div className=" flex justify-content-left">
                        <h3>Add Service Location</h3>
                        <div className="ml-4 align-right"></div>
                    </div>
                    <div className="p-fluid grid question-div-wrapper mt-4">
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label">
                                <InputText id="locationName" name="locationName" onChange={(e)=>{setServiceLocation(e.target.value);setErr({ ...err, locationName: "" });}} value={serviceLocation} />
                                <label htmlFor="locationName">Service Location Name</label>
                            </span>
                            <div className="text-danger">{err.locationName}</div>
                        </div>

                    </div>
                    <div className="flex justify-content-left mt-3 ">
                        {
                            <Button type="button" className="p-button-md p-button-success" onClick={handleSubmit}>
                                Add Service Location
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateServiceLocation;
