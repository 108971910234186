import React, { useEffect, useRef, useState } from "react";
import { getData, postData } from "../services/http.service";
import Constants from "../services/constant";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment/moment";
import { InputText } from "primereact/inputtext";
import { socket } from "../utils/socket";

// AllChats component
const AllChats = ({ contacts, getOldChat, setActiveChat, activeChat }) => (
    <div className="sidebar">
        <h2 className="text-center">Chats</h2>
        <ul>
            {contacts?.map((contact, index) => (
                <li
                    key={index}
                    className={`cursor-pointer ${activeChat?._id === contact?._id ? "active-chat" : ""}`}
                    onClick={() => {
                        getOldChat(contact?._id);
                        setActiveChat(contact);
                    }}
                >
                    <img src={Constants.BASE_URL + contact?.serviceId?.serviceImage} alt="Profile Pic" />
                    <div className="contact-info">
                        <h3>{contact?.serviceId?.serviceName}</h3>
                        <p>User - {contact?.user?.firstName}</p>
                        <p>Provider - {contact?.provider?.firstName}</p>
                    </div>
                </li>
            ))}
        </ul>
    </div>
);

// user window component
const UserWindow = ({ activeChat, messages, receiverRole, InputField }) => (
    <div className="chat-window">
        <UserHeader activeChat={activeChat} receiverRole={receiverRole} />
        <UserChatArea messages={messages} />
        {InputField({ receiverRole })}
    </div>
);

// UserHeader component
const UserHeader = ({ activeChat, receiverRole }) => (
    <div className="header">
        <img src={Constants.BASE_URL + activeChat?.serviceId?.serviceImage} alt="Profile Pic" />
        <div>
            <h3>{receiverRole === "User" ? "User" : "Provider"}</h3>
            <p>{receiverRole === "User" ? activeChat?.user?.firstName : activeChat?.provider?.firstName}</p>
        </div>
    </div>
);

// UserChat area component
const UserChatArea = ({ messages }) => {
    const containerRef = useRef(null);
    // Function to scroll to the bottom of the container
    const scrollToBottom = () => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    };
    // Scroll to bottom on component mount and whenever content changes
    useEffect(() => {
        scrollToBottom();
    }, [messages]); // Run only once on component mount

    return (
        <div ref={containerRef} className="chat-area px-1">
            {messages.map((message, index) => (
                <UserMessage key={index} {...message} />
            ))}
        </div>
    );
};
// user Message component
const UserMessage = ({ sender, message, timestamp }) => (
    <div className={`message ${sender === "Admin" ? "sent" : "received"}`}>
        <p>
            {/* <strong>{sender}</strong>: */}
            {message}
        </p>
        <span>{moment(timestamp).startOf("seconds").fromNow()}</span>
    </div>
);

// Main Chat Web UI component
const ChatComponent = () => {
    const location = useLocation();

    console.log("location", location);
    const [dialogs, setDialogs] = useState([]);
    const [userChat, setUserChat] = useState([]);
    const [newMessage, setNewMessage] = useState({ User: "", Provider: "" });
    const [providerChat, setProviderChat] = useState([]);
    const [activeChat, setActiveChat] = useState({});
    const adminId = JSON.parse(localStorage.getItem("admin"));

    const getOldChat = (data) => {
        console.log("working", activeChat);
        postData(Constants.END_POINT.GETOLDCHAT, {
            roomId: data || activeChat?._id,
            adminId: adminId,
        })
            .then((res) => {
                if (res.success) {
                    let userMessage = res.data.oldChat.filter((chat) => chat.receiverRole === "User" || chat.senderRole === "User").map((chat) => ({ sender: chat.senderRole, message: chat.message, timestamp: chat.time }));
                    let providerMessage = res.data.oldChat.filter((chat) => chat.receiverRole === "Provider" || chat.senderRole === "Provider").map((chat) => ({ sender: chat.senderRole, message: chat.message, timestamp: chat.time }));
                    setUserChat(userMessage);
                    setProviderChat(providerMessage);
                } else {
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        getDialogs();
    }, []);

    useEffect(() => {
        if (!socket.connected) {
            console.log("socket", socket);
            socket.connect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket.connected]);
    useEffect(() => {
        console.log("socket", socket, activeChat);
        socket.on("connect", () => {
            console.log("Connected===>", socket.id);
            socket.emit("adminJoin", { adminId: adminId });
        });

        socket.on("roomAction", (e) => {
            console.log(activeChat);
            getOldChat();
            getDialogs();
        });

        socket.on("disconnect", () => console.log("disconnected"));
        return () => {
            socket.off("connect");
            socket.off("adminJoin");
            socket.off("roomAction");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket.connected, activeChat]);
    console.log("activeChat", activeChat, userChat, providerChat);

    useEffect(() => {
        socket.on("adminJoinRoom", (e) => {
            console.log("adminJoinRoom===>", e);
        });

        return () => {
            socket.off("adminJoinRoom");
        };
    }, []);

    useEffect(() => {
        if (activeChat._id) {
            socket.emit("adminJoinRoom", { adminId: adminId, roomId: activeChat._id });
        }
    }, [activeChat._id]);

    useEffect(() => {
        postData(Constants.END_POINT.CREATE_ROOM, { userId: location?.state?.userId, serviceId: location?.state?.serviceId, providerId: location?.state?.providerId })
            .then((res) => {
                if (res.success) {
                }
            })
            .catch((err) => console.log(err));
    }, []);

    // Input field component
    const handleInputChange = (value, receiverRole) => {
        setNewMessage({ ...newMessage, [receiverRole]: value });
    };

    const InputField = ({ receiverRole }) => {
        return (
            <div className="input-field">
                {/* <InputText id="title" name="title" onChange={(e) => handleInputChange(e.target.value, receiverRole)} /> */}
                <InputText className="col-8" value={newMessage[receiverRole]} name={receiverRole} onChange={(e) => handleInputChange(e.target.value, receiverRole)} placeholder="Type a message..." />{" "}
                <button
                    onClick={() => {
                        Submit(receiverRole);
                        setNewMessage({ User: "", Provider: "" });
                    }}
                    disabled={!newMessage[receiverRole].length}
                >
                    Send
                </button>
            </div>
        );
    };

    const getDialogs = (e) => {
        console.log("activeChat", activeChat);
        getData(Constants.END_POINT.GETDIALOGS)
            .then((res) => {
                if (res.success) {
                    setDialogs(res.data);
                } else {
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const Submit = (receiverRole) => {
        postData(Constants.END_POINT.SENDMSG, {
            roomId: activeChat._id,
            msg: newMessage[receiverRole],
            type: "text",
            userId: adminId,
            senderRole: "Admin",
            receiverRole: receiverRole,
        })
            .then((res) => {
                if (res.success) {
                    getOldChat();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <div id="user" className="card overflow-auto p-3 ">
            <div className="chat-web-ui w-full">
                {activeChat?._id ? <UserWindow InputField={InputField} receiverRole="User" messages={userChat} activeChat={activeChat} /> : <p className="mx-auto">User's chat will be loaded here</p>}
                <AllChats getOldChat={getOldChat} activeChat={activeChat} contacts={dialogs} setActiveChat={setActiveChat} />
                {activeChat?._id ? <UserWindow InputField={InputField} receiverRole="Provider" messages={providerChat} activeChat={activeChat} /> : <p className="mx-auto">Provider's chat will be loaded here</p>}
            </div>
        </div>
    );
};

export default ChatComponent;
