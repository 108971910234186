import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { deleteData, getData, putData } from "../../services/http.service";
import Constants from "../../services/constant";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { InputSwitch } from "primereact/inputswitch";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { useHistory } from "react-router-dom";

const TermsConditions = () => {
    const toast = useRef(null);
    const history = useHistory();
    const [err, setErr] = useState({});
    const [termsCondition, setTermsCondition] = useState({
        heading: "",
        paragraphText: "",
        id: "",
    });
    const [loading, setLoading] = useState(true);
    const [isEditable, setIsEditable] = useState(false);

    const deleteTermsCondition = () => {
        deleteData(Constants.END_POINT.DELETE_TERM_CONDITION + termsCondition.id).then((res) => {
            if (res.success) {
                toast.current.show({ severity: "info", summary: "", detail: "Terms and Condition Deleted Succesfully", life: 3000 });
                setIsEditable(false);
                getAllTermsCondition();
            }
        });
    };

    const getAllTermsCondition = () => {
        getData(Constants.END_POINT.GET_TERMS_CONDITION)
            .then((res) => {
                if (res.success) {
                    let obj = {
                        heading: res?.data?.heading,
                        paragraphText: res?.data?.paragraphText,
                        id: res?.data?._id,
                    };
                    setTermsCondition(obj);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const validateForm = () => {
        let errors = {};
        if (termsCondition.heading.length == 0) {
            errors.seasonName = "heading is required";
        }
        if (termsCondition.paragraphText.length == 0) {
            errors.seasonName = "terms and conditions is required";
        }

        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        const form = validateForm();
        if (form) {
            let paylaod = {
                heading: termsCondition.heading,
                paragraphText: termsCondition.paragraphText,
            };
            putData(Constants.END_POINT.UPDATE_TERM_CONDITION + termsCondition.id, paylaod)
                .then((res) => {
                    if (res.success) {
                        console.log("res", res);
                        toast.current.show({ severity: "info", summary: "", detail: "Terms and Conditions has been Updated Succesfully", life: 3000 });
                        setIsEditable(false);
                        getAllTermsCondition();
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        setLoading(false);
    };

    const handleChangeTerms = (e) => {
        const { name, value } = e.target;
        setTermsCondition((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
        setErr({ ...err, [name]: "" });
    };

    const acceptFunc = () => {
        deleteTermsCondition();
        // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    };

    const reject = () => {
        // toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };

    const deleteConfirm = () => {
        confirmDialog({
            message: "Do you want to delete this record?",
            header: "Delete Confirmation",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            rejectClassName: "cancel-button",
            accept: () => acceptFunc(),
            reject,
        });
    };

    console.log("termsms", termsCondition);

    useEffect(() => {
        getAllTermsCondition();
    }, []);
    return (
        <div className="col-12">
            <div className="card">
                {loading ? (
                    <div className="overlay-loading">
                        <div class="spinner-border text-dark" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : null}
                <div className="sm:flex justify-content-between mb-4">
                    <Toast ref={toast} />
                    <h5>Terms And Conditions</h5>
                    <div className="flex flex-column sm:flex-row justify-content-end mr-3">
                        {!isEditable && termsCondition?.heading != undefined && termsCondition?.paragraphText != undefined ? (
                            <>
                                <Button className="p-button-info mb-2 md:mb-0" icon="pi pi-pencil" label="Edit " style={{ height: "max-content", marginLeft: "1rem" }} onClick={() => setIsEditable(true)} />

                                <Button className="p-button-info mb-2 md:mb-0" icon="pi pi-trash" label="Delete " style={{ height: "max-content", marginLeft: "1rem" }} onClick={deleteConfirm} />
                            </>
                        ) : null}
                        {termsCondition?.heading == undefined && termsCondition?.paragraphText == undefined ? (
                            <Link to="/create-terms-conditions" style={{ height: "max-content" }}>
                                <Button className="p-button-info mb-2 md:mb-0" icon="pi pi-plus" label="Create Terms And Conditions" />
                            </Link>
                        ) : null}

                        {/* <Button label="Export" icon="pi pi-upload" className="p-button-help ml-3" onClick={() => ExportCSV(exportData)} /> */}
                    </div>
                </div>
                {termsCondition?.heading == undefined && termsCondition?.paragraphText == undefined ? (
                    <div>No Terms and Conditions Found</div>
                ) : (
                    <div className="p-fluid grid question-div-wrapper mt-4">
                        <div className="field col-12 md:col-12">
                            <span className="p-float-label">
                                <InputText className={isEditable ? "editable" : "noEditable"} id="heading" name="heading" onChange={handleChangeTerms} value={termsCondition.heading} />
                                <label htmlFor="heading">Heading</label>
                            </span>
                        </div>

                        <div className="field col-12 md:col-12">
                            <span className="p-float-label">
                                {/* <InputText id="paragraphText" name="paragraphText" onChange={(e)=>{setSeason(e.target.value);setErr({ ...err, paragraphText: "" });}} value={termsCondition.paragraphText} /> */}
                                <InputTextarea className={isEditable ? "editable" : "noEditable"} id="paragraphText" name="paragraphText" onChange={handleChangeTerms} value={termsCondition.paragraphText} rows={5} cols={30} />
                                <label htmlFor="paragraphText">Terms and Conditions</label>
                            </span>
                        </div>
                    </div>
                )}

                <div className="flex justify-content-left mt-3 ">
                    {isEditable ? (
                        <Button type="button" className="p-button-md p-button-success" onClick={handleSubmit}>
                            Update Terms and Conditions
                        </Button>
                    ) : null}
                </div>
                {/* <DataTable value={termsCondition} paginator rows={10} loading={loading}>
            <Column field="heading"  header="Season Name" sortable  />
            <Column field="block" header="Action" body={ActionBodyTemplate} style={{width:'10px'}} />
        </DataTable> */}
            </div>
        </div>
    );
};

export default TermsConditions;
