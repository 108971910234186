import React, { useState, useEffect, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import Constants from "../services/constant";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { useHistory } from "react-router-dom";
import { getData, postData, postData1, putData, putData1 } from "../services/http.service";
import { InputTextarea } from "primereact/inputtextarea";
import Cross from "../assets/demo/flags/cross.png";
import moment from "moment/moment";
import { Image } from "primereact/image";
import profile from "../assets/demo/flags/profile.png";
import service_dummy from "../assets/demo/flags/service-dummy.svg";
import { Chips } from "primereact/chips";
import { MultiSelect } from "primereact/multiselect";
import { getImageUrl } from "../utils/imageConversions";

const UserView = () => {
    const { id } = useParams();
    const toast = useRef(null);
    const history = useHistory();
    const [user, setUser] = useState({});
    const [bookings, setBookings] = useState([]);
    const [userImg, setUserImg] = useState(null);
    const [userView, setUserView] = useState({
        firstName: "",
        lastName: "",
        image: "",
        email: "",
        countryCode: "",
        mobile: "",
        isVerified: "",
    });

    const [err, setErr] = useState({});
    const [loading, setLoading] = useState(true);

    console.log("err", err);
    console.log("userView", userView);

    useEffect(() => {
        getSingleUser();
        getAllBookings();
    }, []);

    const [datePicker, setDatePicker] = useState({
        bookingsFrom: null,
        bookingsTo: null,
    });
    useEffect(() => {
        if (datePicker?.bookingsFrom && datePicker?.bookingsTo) {
            getAllBookings1();
        } else {
            getAllBookings();
        }
    }, [datePicker]);

    const getSingleUser = () => {
        getData(Constants.END_POINT.USERS_VIEW + id)
            .then((res) => {
                if (res.success) {
                    // console.log("resss",res.data)
                    let obj = {
                        firstName: res.data.firstName,
                        lastName: res.data.lastName,
                        image: res.data.image,
                        email: res.data.email,
                        countryCode: res.data.countryCode,
                        mobile: res.data.mobile,
                    };
                    setUserView(obj);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const bookingDateTemplate = (rowData) => {
        return moment(rowData?.bookingDate).format("DD-MM-YYYY");
    };
    const statusbodyTemplate = (rowData) => {
        let statusClass = "";
        let statusText = "";

        switch (rowData.status) {
            case "PENDING":
                statusClass = "outofstock";
                statusText = "Pending";
                break;
            case "CONFIRMED":
                statusClass = "instock";
                statusText = "Confirmed";
                break;
            case "CANCELLED":
                statusClass = "outofstock";
                statusText = "Cancelled";
                break;
            case "IN_PROGRESS":
                statusClass = "lowstock";
                statusText = "In Progress";
                break;
            case "IN_REVIEW":
                statusClass = "lowstock";
                statusText = "In Review";
                break;
            case "CONCERN_RAISED":
                statusClass = "lowstock";
                statusText = "Concern Raised";
                break;
            case "COMPLETED":
                statusClass = "instock";
                statusText = "Completed";
                break;
            case "UNCONFIRMED":
                statusClass = "outofstock";
                statusText = "Unconfirmed";
                break;
            default:
                statusClass = "unknown";
                statusText = "Unknown";
        }
        // return <span className={`product-badge status-${rowData.status === "CONFIRMED" ? "instock" : "outofstock"}`}>{rowData.status}</span>;
        return <span className={`product-badge status-${statusClass}`}>{statusText}</span>;
    };
    const servicesTemplate = (rowData) => {
        return rowData?.service.serviceName;
        // return (
        //     <Link
        //         to={{
        //             pathname: `/providerInfo/${rowData?._id}`,
        //             state: {
        //                 booking: rowData?.business?.location,
        //             },
        //         }}
        //     >
        //         {rowData?.services.map((item, i) => (i ? ", " + item?.serviceName : item?.serviceName))}
        //     </Link>
        // );
    };
    function dateTemplate(date) {
        return date.day;
    }
    const handleChange = (name) => (event) => {
        let value = event.target.value;
        setDatePicker({ ...datePicker, [name]: value });
    };
    let { bookingsFrom, bookingsTo } = datePicker;
    bookingsFrom = moment(new Date(bookingsFrom)).format("DD-MM-YYYY");
    bookingsTo = moment(new Date(bookingsTo)).format("DD-MM-YYYY");

    const getAllBookings = () => {
        postData(Constants.END_POINT.GET_USER_BOOKINGS + id)
            .then((res) => {
                if (res.success) {
                    setBookings(res.data);
                }
            })
            .catch((err) => console.log(err));
    };
    const getAllBookings1 = () => {
        postData(Constants.END_POINT.GET_USER_BOOKINGS + id, {
            bookingsFrom: bookingsFrom,
            bookingsTo: bookingsTo,
        })
            .then((res) => {
                if (res.success) {
                    setBookings(res.data);
                }
            })
            .catch((err) => console.log(err));
    };
    const bookingTemplate = (rowData) => {
        return (
            // <Link
            //     to={{
            //         pathname: `/bookingDetail/${rowData?._id}`,
            //         state: {
            //             booking: rowData?.business?.location,
            //             user: rowData?.address?.location,
            //         },
            //     }}
            // >
            <Link
                to={{
                    pathname: `/provider-view/${rowData?.provider}`,
                    // state: {
                    //     booking: rowData?.business?.location,
                    // },
                }}
            >
                {rowData?.provider?.firstName}
            </Link>
        );
    };
    console.log("userViewoo", userView);

    return (
        <div className="grid">
            <div className="col-12 md:col-8 mx-auto">
                <div className="card ">
                    {/* <MapContainer coordinates={user?.business?.location?.coordinates} /> */}
                    <div>
                        <h3>Bookings </h3>
                        <div className="flex mb-3 lign-items-end">
                            <div>
                                <label className="font-semibold">From Date</label>
                                <Calendar className="mx-2" name="bookingsFrom" value={datePicker?.bookingsFrom} onChange={handleChange("bookingsFrom")} dateTemplate={dateTemplate} />
                            </div>
                            <div>
                                <label className="font-semibold">To Date</label>
                                <Calendar className="mx-2" name="bookingsTo" value={datePicker?.bookingsTo} onChange={handleChange("bookingsTo")} dateTemplate={dateTemplate} />
                            </div>
                            <div className="mx-2 d-flex flex-column justify-content-end">
                                {/* <label>.</label> */}
                                <Button
                                    type="button"
                                    onClick={() =>
                                        setDatePicker({
                                            bookingsFrom: null,
                                            bookingsTo: null,
                                        })
                                    }
                                    // className="p-button-outlined  align-bottom"
                                >
                                    Reset
                                </Button>
                            </div>
                        </div>

                        {bookings?.length ? (
                            <>
                                <DataTable value={bookings} responsiveLayout="scroll">
                                    <Column body={servicesTemplate} header="Services"></Column>
                                    <Column body={bookingTemplate} header="Provider Name"></Column>
                                    <Column body={bookingDateTemplate} header="Booking Date"></Column>
                                    {/* <Column body={startTime} header="Start Time"></Column> */}
                                    {/* <Column field="duration" header="Duration"></Column> */}
                                    <Column body={statusbodyTemplate} header="Status"></Column>
                                </DataTable>
                            </>
                        ) : (
                            "No Bookings Found"
                        )}
                    </div>
                </div>
            </div>
            <div className="col-12 md:col-4">
                <div className="card">
                    <h3>User Information </h3>
                    <div className="text-xl font-bold">{userView?.firstName}</div>
                    <a href={`mailto:${userView?.email}`}>{userView?.email}</a>
                    {userView?.mobile ? <div className="text-xl font-bold">{userView?.countryCode + userView?.mobile}</div> : null}
                    <div className="text-xl ">{userView?.dob}</div>

                    <div className="flex align-items-center justify-content-between">
                        <div className="flex align-items-center">
                            <i className="pi pi-tag mr-2" />
                            <span className="font-semibold">User</span>
                        </div>
                        {/* <span className={`product-badge status-${user?.isVerified === "VERIFIED" ? "instock" : "outofstock"}`}> {user?.isVerified == "VERIFIED" ? "Verified" : "Rejected"}</span> */}
                    </div>
                    <div className="text-center">
                        <img src={userView?.image && getImageUrl(userView?.image)} onError={(e) => (e.target.src = profile)} alt="" className="w-9 shadow-2 my-3 mx-0" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserView;
