import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useHistory } from "react-router-dom";
import { postData, postData1 } from "../../services/http.service";
import Constants from "../../services/constant";
import { Chips } from "primereact/chips";

const CreateSeason = () => {
    const toast = useRef(null);
    const history = useHistory();

    const [err, setErr] = useState({});
    const [season, setSeason] = useState(null);

console.log("season",season)


    const validateForm = () => {
        let errors = {};
        if (!season) {
            errors.seasonName = "Season Name is required";
        }

        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = validateForm();
        if (form) {
          let  payload={
                seasonName:season
            }
            postData(Constants.END_POINT.CREATE_SEASON, payload)
            .then((res) => {
                if (res.success) {
                    console.log("res", res);
                    toast.current.show({ severity: "info", summary: "", detail: "Season has been Created Succesfully", life: 3000 });
                    setTimeout(()=>history.push("/seasons"), 1000)
                    ;
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
    }

    return (
        <div className="grid">
            <div className="col-12 md:col-12 mx-auto">
                <Toast ref={toast} />
                <div className="card ">
                    <div className=" flex justify-content-left">
                        <h3>Add Season</h3>
                        <div className="ml-4 align-right"></div>
                    </div>
                    <div className="p-fluid grid question-div-wrapper mt-4">
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label">
                                <InputText id="seasonName" name="seasonName" onChange={(e)=>{setSeason(e.target.value);setErr({ ...err, seasonName: "" });}} value={season} />
                                <label htmlFor="seasonName">Season Name</label>
                            </span>
                            <div className="text-danger">{err.seasonName}</div>
                        </div>

                    </div>
                    <div className="flex justify-content-left mt-3 ">
                        {
                            <Button type="button" className="p-button-md p-button-success" onClick={handleSubmit}>
                                Add Season
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateSeason;
