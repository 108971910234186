import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { deleteData, getData } from "../../services/http.service";
import Constants from "../../services/constant";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { InputSwitch } from "primereact/inputswitch";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const ServiceQuestions = () => {
    const { id } = useParams();
    const toast = useRef(null);

    const [serviceQuestions, setServiceQuestions] = useState([]);
    const [loading, setLoading] = useState(true);

    const deleteServiceQuestion = (id) => {
        deleteData(Constants.END_POINT.DELETE_SERVICE_QUESTION + id).then((res) => {
            if (res.success) {
                toast.current.show({ severity: "info", summary: "", detail: "Season Deleted Succesfully", life: 3000 });
                getAllServiceQuestions();
            }
        });
    };

    const getAllServiceQuestions = () => {
        getData(Constants.END_POINT.ALL_SERVICE_QUESTIONS + id)
            .then((res) => {
                if (res.success) {
                    setServiceQuestions(res.data);
                    console.log("sadsa");
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    console.log("loading", loading);

    const acceptFunc = (id) => {
        deleteServiceQuestion(id);
        // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    };

    const reject = () => {
        // toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };

    const deleteConfirm = (id) => {
        confirmDialog({
            message: "Do you want to delete this record?",
            header: "Delete Confirmation",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            rejectClassName: "cancel-button",
            accept: () => acceptFunc(id),
            reject,
        });
    };

    const changeStatus = (id) => {
        getData(Constants.END_POINT.ENABLE_DISABLE_QUESTION + id)
            .then((res) => {
                if (res.success) {
                    getAllServiceQuestions();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const ActionBodyTemplate = (rowData) => {
        return (
            <div className="flex">
                <Link to={`/service-question-edit/${rowData?._id}`}>
                    <Button type="button" className="p-button-info mx-2">
                        Edit
                    </Button>
                </Link>

                <Button type="button" className="p-button-danger " onClick={() => deleteConfirm(rowData?._id)}>
                    Delete
                </Button>

                <Link to={`/service-questions-option/${rowData?._id}`}>
                    <Button type="button" className="p-button-info mx-2">
                        Options
                    </Button>
                </Link>
            </div>
        );
    };

    const statusTemplate = (rowData) => {
        console.log("rowData", rowData.isActive);
        return (
            <div className="flex" style={{ alignItems: "center" }}>
                <InputSwitch checked={rowData?.isActive} onChange={(e) => changeStatus(rowData._id)} />
                {rowData?.isActive ? <div className="status-button status-active">Active</div> : <div className="status-button status-inactive">Inctive</div>}
            </div>
        );
    };

    useEffect(() => {
        getAllServiceQuestions();
    }, []);
    return (
        <div className="col-12">
            <div className="card">
                <div className="md:flex justify-content-between mb-4">
                    <Toast ref={toast} />
                    <h5>Service Questions</h5>
                    <div className="flex justify-content-end mr-3">
                        <Link to={`/create-service-question/${id}`}>
                            <Button className="p-button-info" icon="pi pi-plus" label="Create Service Question" />
                        </Link>
                        {/* <Button label="Export" icon="pi pi-upload" className="p-button-help ml-3" onClick={() => ExportCSV(exportData)} /> */}
                    </div>
                </div>
                <DataTable value={serviceQuestions} paginator rows={10} loading={loading} responsiveLayout="scroll">
                    <Column field="questionName" header="Question Name" sortable />
                    <Column field="questionDetail" header="Question Details" sortable />
                    <Column field="questionType" header="Question Type" sortable />
                    <Column field="selectType" header="Select Type" sortable />
                    <Column field="status" header="Status" sortable body={statusTemplate} />
                    <Column field="block" header="Action" body={ActionBodyTemplate} style={{ width: "10px" }} />
                </DataTable>
            </div>
        </div>
    );
};

export default ServiceQuestions;
