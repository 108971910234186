import Bookings from "../components/Bookings";
import Dashboard from "../components/Dashboard";
import Provider from "../components/Provider/Provider";
import ProviderView from "../components/Provider/ProviderView";
import CreateQuestionOption from "../components/QuestionOptions/CreateQuestionOption";
import QuestionOptionEdit from "../components/QuestionOptions/QuestionOptionEdit";
import QuestionOptions from "../components/QuestionOptions/QuestionOptions";
import CreateReason from "../components/Reasons/CreateReason";
import ReasonEdit from "../components/Reasons/ReasonEdit";
import Reasons from "../components/Reasons/Reasons";
import Reports from "../components/Reports";
import CreateSeason from "../components/Seasons/CreateSeason";
import SeasonEdit from "../components/Seasons/SeasonEdit";
import Seasons from "../components/Seasons/Seasons";
import CreateServiceLocation from "../components/ServiceLocations/CreateServiceLocation";
import ServiceLocationEdit from "../components/ServiceLocations/ServiceLocationEdit";
import ServiceLocations from "../components/ServiceLocations/ServiceLocations";
import CreateServiceQuestion from "../components/ServiceQuestions/CreateServiceQuestion";
import ServiceQuestionEdit from "../components/ServiceQuestions/ServiceQuestionEdit";
import ServiceQuestions from "../components/ServiceQuestions/ServiceQuestions";
import CreateServices from "../components/Services/CreateServices";
import Services from "../components/Services/Services";
import ServicesEdit from "../components/Services/ServicesEdit";
import CreateTermsCondition from "../components/TermsCondition/CreateTermsCondition";
import TermsConditions from "../components/TermsCondition/TermsConditions";
import UserView from "../components/UserView";

import Users from "../components/Users";
import CreateLoadOption from "../components/insideOptions/CreateLoadOption";
import LoadOptionEdit from "../components/insideOptions/LoadOptionEdit";
import LoadOptions from "../components/insideOptions/LoadOptions";
import Chat from "../pages/Chat";
// import Weapons from "../components/weapons/Weapons";
import Profile from "../pages/Profile";

// import CreateWeapon from "../components/weapons/CreateWeapon";
// import WeaponEdit from "../components/weapons/WeaponEdit";
// import TrackerQuestions from "../components/TrackerQuestions/TrackerQuestions";
// import CreateTrackerQuestions from "../components/TrackerQuestions/CreateTrackerQuestions";
// import TrackerQuestionEdit from "../components/TrackerQuestions/TrackerQuestionEdit";
// import Species from "../components/species/Species";
// import CreateSpecies from "../components/species/CreateSpecies";
// import SpeciesEdit from "../components/species/SpeciesEdit";
// import PointMarker from "../components/PointMarker.js/PointMarker";
// import CreatePointerMarker from "../components/PointMarker.js/CreatePointMarker";
// import PointMarkerEdit from "../components/PointMarker.js/PointMarkerEdit";
// import HuntingQuestions from "../components/HuntingQuestions/HuntingQuestions";
// import CreateHuntingQuestions from "../components/HuntingQuestions/CreateHuntingQuestions";
// import HuntingQuestionEdit from "../components/HuntingQuestions/HuntingQuestionEdit";
// import TrackingQuestions from "../components/TrackingQuestions/TackingQuestions";
// import CreateTrackingQuestions from "../components/TrackingQuestions/CreateTrackingQuestions";
// import TrackingQuestionEdit from "../components/TrackingQuestions/TrackingQuestionEdit";
// import TargetPoint from "../components/TargetPoint/TargetPoint";
// import CreateTargetPoint from "../components/TargetPoint/CreateTargetPoint";
// import TargetPointEdit from "../components/TargetPoint/TargetPointEdit";
// import BodyParts from "../components/BodyParts/BodyParts";
// import CreateBodyPart from "../components/BodyParts/CreateBodyPart";
// import BodyPartEdit from "../components/BodyParts/BodyPartEdit";
// import Comments from "../components/Reports/Comments";
// import ViewComment from "../components/Reports/ViewComment";
// import Posts from "../components/Reports/Posts";
// import ViewPost from "../components/Reports/ViewPost";

export const PrivateRoutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
    },
    {
        path: "/profile",
        name: "Profile",
        component: Profile,
    },

    {
        path: "/users",
        name: "Users",
        component: Users,
    },
    {
        path: "/user-view/:id",
        name: "UserView",
        component: UserView,
    },

    {
        path: "/providers",
        name: "Provider",
        component: Provider,
    },
    {
        path: "/chat",
        name: "chat",
        component: Chat,
    },
    {
        path: "/provider-view/:id",
        name: "ProviderView",
        component: ProviderView,
    },
    {
        path: "/seasons",
        name: "Seasons",
        component: Seasons,
    },
    {
        path: "/create-season/",
        name: "CreateSeason",
        component: CreateSeason,
    },
    {
        path: "/season-edit/:id",
        name: "SeasonEdit",
        component: SeasonEdit,
    },
    {
        path: "/services",
        name: "Services",
        component: Services,
    },
    {
        path: "/create-service/",
        name: "CreateServices",
        component: CreateServices,
    },
    {
        path: "/service-edit/:id",
        name: "ServicesEdit",
        component: ServicesEdit,
    },
    {
        path: "/service-questions/:id",
        name: "ServiceQuestions",
        component: ServiceQuestions,
    },
    {
        path: "/create-service-question/:id",
        name: "CreateServiceQuestion",
        component: CreateServiceQuestion,
    },
    {
        path: "/service-question-edit/:id",
        name: "ServiceQuestionEdit",
        component: ServiceQuestionEdit,
    },
    {
        path: "/service-questions-option/:id",
        name: "QuestionOptions",
        component: QuestionOptions,
    },
    {
        path: "/create-load-option/:id",
        name: "CreateLoadOption",
        component: CreateLoadOption,
    },
    {
        path: "/load-option-edit/:id",
        name: "LoadOptionEdit",
        component: LoadOptionEdit,
    },
    {
        path: "/load-option/:id",
        name: "LoadOptions",
        component: LoadOptions,
    },
    {
        path: "/create-service-question-option/:id",
        name: "CreateQuestionOption",
        component: CreateQuestionOption,
    },
    {
        path: "/service-question-option-edit/:id",
        name: "QuestionOptionEdit",
        component: QuestionOptionEdit,
    },
    {
        path: "/terms-conditions",
        name: "TermsConditions",
        component: TermsConditions,
    },
    {
        path: "/create-terms-conditions/",
        name: "CreateTermsCondition",
        component: CreateTermsCondition,
    },
    {
        path: "/service-locations",
        name: "ServiceLocations",
        component: ServiceLocations,
    },
    {
        path: "/create-service-location/",
        name: "CreateServiceLocation",
        component: CreateServiceLocation,
    },
    {
        path: "/service-location-edit/:id",
        name: "ServiceLocationEdit",
        component: ServiceLocationEdit,
    },

    {
        path: "/reasons",
        name: "Reasons",
        component: Reasons,
    },
    {
        path: "/create-reason/",
        name: "CreateReason",
        component: CreateReason,
    },
    {
        path: "/reason-edit/:id",
        name: "ReasonEdit",
        component: ReasonEdit,
    },
    {
        path: "/reports",
        name: "Reports",
        component: Reports,
    },
    {
        path: "/bookings",
        name: "Bookings",
        component: Bookings,
    },
    // {
    //     path: "/create-weapon/",
    //     name: "CreateWeapon",
    //     component: CreateWeapon,
    // },
    // {
    //     path: "/weapon-edit/:id",
    //     name: "WeaponEdit",
    //     component: WeaponEdit,
    // },
    // {
    //     path: "/tracker-questions",
    //     name: "TrackerQuestions",
    //     component: TrackerQuestions,
    // },
    // {
    //     path: "/create-tracker-questions/",
    //     name: "CreateTrackerQuestions",
    //     component: CreateTrackerQuestions,
    // },
    // {
    //     path: "/tracker-questions-edit/:id",
    //     name: "TrackerQuestionEdit",
    //     component: TrackerQuestionEdit,
    // },

    // {
    //     path: "/species",
    //     name: "Species",
    //     component: Species,
    // },
    // {
    //     path: "/create-species/",
    //     name: "CreateSpecies",
    //     component: CreateSpecies,
    // },
    // {
    //     path: "/species-edit/:id",
    //     name: "SpeciesEdit",
    //     component: SpeciesEdit,
    // },
    // {
    //     path: "/point-marker",
    //     name: "PointMarker",
    //     component: PointMarker,
    // },
    // {
    //     path: "/create-point-marker/",
    //     name: "CreatePointerMarker",
    //     component: CreatePointerMarker,
    // },
    // {
    //     path: "/point-marker-edit/:id",
    //     name: "PointMarkerEdit",
    //     component: PointMarkerEdit,
    // },
    // {
    //     path: "/hunting-questions",
    //     name: "HuntingQuestions",
    //     component: HuntingQuestions,
    // },
    // {
    //     path: "/create-hunting-questions/",
    //     name: "CreateHuntingQuestions",
    //     component: CreateHuntingQuestions,
    // },
    // {
    //     path: "/hunting-questions-edit/:id",
    //     name: "HuntingQuestionEdit",
    //     component: HuntingQuestionEdit,
    // },

    // {
    //     path: "/tracking-questions",
    //     name: "TrackingQuestions",
    //     component: TrackingQuestions,
    // },
    // {
    //     path: "/create-tracking-questions/",
    //     name: "CreateTrackingQuestions",
    //     component: CreateTrackingQuestions,
    // },
    // {
    //     path: "/tracking-questions-edit/:id",
    //     name: "TrackingQuestionEdit",
    //     component: TrackingQuestionEdit,
    // },

    // {
    //     path: "/target-point",
    //     name: "TargetPoint",
    //     component: TargetPoint,
    // },
    // {
    //     path: "/create-target-point/",
    //     name: "CreateTargetPoint",
    //     component: CreateTargetPoint,
    // },
    // {
    //     path: "/target-point-edit/:id",
    //     name: "TargetPointEdit",
    //     component: TargetPointEdit,
    // },
    // {
    //     path: "/body-part",
    //     name: "BodyParts",
    //     component: BodyParts,
    // },
    // {
    //     path: "/create-body-part/",
    //     name: "CreateBodyPart",
    //     component: CreateBodyPart,
    // },
    // {
    //     path: "/body-part-edit/:id",
    //     name: "BodyPartEdit",
    //     component: BodyPartEdit,
    // },

    // {
    //     path: "/report-comments",
    //     name: "Comments",
    //     component: Comments,
    // },
    // {
    //     path: "/view-comment/:id",
    //     name: "ViewComment",
    //     component: ViewComment,
    // },

    // {
    //     path: "/report-posts",
    //     name: "Posts",
    //     component: Posts,
    // },
    // {
    //     path: "/view-post/:id",
    //     name: "ViewPost",
    //     component: ViewPost,
    // },
];
