import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { InputText } from "primereact/inputtext";

const Reports = () => {
    const [globalFilter, setGlobalFilter] = useState("");
    const [Reports, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    const nameTemplate = (rowData) => {
        return (
            <div>
                {rowData.firstName} {rowData.lastName}
            </div>
        );
    };

    const mobileTemplate = (rowData) => {
        return (
            <div>
                {rowData.countryCode} {rowData.mobile}
            </div>
        );
    };
    const getHeader = () => {
        return (
            <div className="flex justify-content-end">
                <div className="p-input-icon-left">
                    <i className="pi pi-search"></i>
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search" />
                </div>
            </div>
        );
    };

    return (
        <div className="col-12">
            <div className="card">
                <h5>Reports</h5>
                <DataTable value={Reports} paginator header={getHeader} globalFilter={globalFilter} rows={10} loading={loading}>
                    <Column
                        field="title
"
                        header="Title
"
                        sortable
                        body={nameTemplate}
                    />
                    <Column
                        field="Comment
"
                        header="comment
"
                        sortable
                    />
                    <Column
                        field="Business Name
"
                        header="Business Name
                        "
                        sortable
                        body={mobileTemplate}
                    />
                </DataTable>
            </div>
        </div>
    );
};

export default Reports;
